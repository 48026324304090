import {CLIENT_DOMAIN} from '../client';


export interface Auth0Config {
  CLIENT_DOMAIN: string
  AUTH0_CLIENT_ID: string
  AUTH0_CLIENT_SECRET: string
  AUTH0_DOMAIN: string
  AUTH0_AUDIENCE: string
  AUTH0_COOKIE_SECRET: string
  AUTH0_COOKIE_LIFETIME: number | undefined
}

/**
 * Settings exposed to the client.
 */
export const config: Auth0Config = {
  CLIENT_DOMAIN: CLIENT_DOMAIN as string,
  AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID as string,
  AUTH0_CLIENT_SECRET: process.env.AUTH0_CLIENT_SECRET as string,
  AUTH0_DOMAIN: process.env.AUTH0_DOMAIN as string,
  AUTH0_AUDIENCE: process.env.AUTH0_AUDIENCE as string,
  AUTH0_COOKIE_SECRET: process.env.AUTH0_COOKIE_SECRET as string,
  AUTH0_COOKIE_LIFETIME: process.env.AUTH0_COOKIE_LIFETIME
    ? Number.parseInt(process.env.AUTH0_COOKIE_LIFETIME, 10)
    : void 0,
};
