export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values.BigInt can represent values between -(2^53) + 1 and 2^53 - 1. */
  BigInt: any;
  /** The `UUID` scalar type represents UUID values as specified by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
  /** Valid IANA timezone name. */
  IanaTimezone: any;
  /** A valid date or date-time in format: YYYY-MM-DD, YYYY-MM-DD hh:mm:ss, or YYYY-MM-DDThh:mm:ssZ. */
  ISODateOptionalTime: any;
  /** GeoJSON format Geography data. */
  Geography: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** String value in ISO 8601 Date-only format: "YYYY-MM-DD". */
  ISODate: any;
};

export type Query = {
  __typename: 'Query';
  node?: Maybe<Node>;
  globalIdLookup?: Maybe<GlobalIdLookup>;
  globalIdReverseLookup?: Maybe<GlobalIdReverseLookup>;
  isOnline?: Maybe<OnlineCheck>;
  standardFileTypeConstraints?: Maybe<Array<FileTypeConstraint>>;
  fileNodeById?: Maybe<IFileNode>;
  folderById?: Maybe<Folder>;
  fileById?: Maybe<IFile>;
  fileContainerById?: Maybe<FileContainer>;
  /**
   * Returns the same set of files as the getContentsDownloadRequest mutation.
   * 
   * path value returned will relative to the root or the value of relativeTo if provided.
   */
  folderContents?: Maybe<FilesBatch>;
  /**
   * Returns the same set of files as the getBatchFileDownloadRequest mutation.
   * 
   * Limited to no more than the weighted sum of 50 folders, 1000 file containers, or 5000 files.
   * 
   * path value returned will be relative to the root or the value of relativeTo if provided.
   */
  filesBatch?: Maybe<FilesBatch>;
  fileNodes: IFileNodeConnection;
  /** List the existing actors that may be granted a role on the node by the current user. */
  assignableActorsForFileNode?: Maybe<IActorConnection>;
  /** List the existing roles that may be assigned to the node by the current user. */
  assignableRolesForFileNode?: Maybe<FileRoleConnection>;
  recycledFileNodeById?: Maybe<IRecycledFileNode>;
  recycledFolderById?: Maybe<RecycledFolder>;
  recycledFileById?: Maybe<IRecycledFile>;
  recycledFileContainerById?: Maybe<RecycledFileContainer>;
  /**
   * Returns the same set of files as the getContentsDownloadRequest mutation.
   * 
   * path value returned will relative to the root or the value of relativeTo if provided.
   */
  recycledFolderContents?: Maybe<FilesBatch>;
  /**
   * Returns the same set of files as the getBatchFileDownloadRequest mutation.
   * 
   * Limited to no more than the weighted sum of 50 folders, 1000 file containers, or 5000 files.
   * 
   * path value returned will be relative to the root or the value of relativeTo if provided.
   */
  recycledFilesBatch?: Maybe<FilesBatch>;
  recycledFileNodes?: Maybe<IRecycledFileNodeConnection>;
  jobById?: Maybe<Job>;
  jobByName?: Maybe<Job>;
  jobsById: Array<Maybe<Job>>;
  jobs: JobConnection;
  jobTemplateById?: Maybe<JobTemplate>;
  milestoneById?: Maybe<Milestone>;
  milestones: MilestoneConnection;
  notifications: UserNotificationConnection;
  notificationsForUsers: UserNotificationConnection;
  myNotifications: UserNotificationConnection;
  /**
   * Requests the organization associated with the current tenant ID request header X-Tenant-ID.
   * Can also be used in conjuction with the user default tenant request header X-Default-Tenant='TRUE'.
   */
  currentTenant?: Maybe<Organization>;
  organizationById?: Maybe<Organization>;
  /**
   * Requests the current user's membership in the current tenant ID.
   * Shares the requirements of Query.me and Query.currentTenant.
   */
  currentMembership?: Maybe<OrganizationMembership>;
  organizationMembershipById?: Maybe<OrganizationMembership>;
  organizations: OrganizationConnection;
  projectById?: Maybe<Project>;
  projectByName?: Maybe<Project>;
  projects: ProjectConnection;
  projectTemplateById?: Maybe<ProjectTemplate>;
  recentTenants?: Maybe<Array<Organization>>;
  recentProjects?: Maybe<Array<Project>>;
  recentJobs?: Maybe<Array<Job>>;
  recentFolders?: Maybe<Array<Folder>>;
  recentFiles?: Maybe<Array<IFile>>;
  templateRepositoryById?: Maybe<TemplateRepository>;
  templateRepositoryBySource?: Maybe<TemplateRepository>;
  templateRepositories: TemplateRepositoryConnection;
  teamById?: Maybe<Team>;
  teams: TeamConnection;
  teamMembers: TeamMemberConnection;
  applicationById?: Maybe<Application>;
  applications?: Maybe<ApplicationConnection>;
  me?: Maybe<User>;
  userById?: Maybe<User>;
  userByEmail?: Maybe<User>;
  users: UserConnection;
  usersById: Array<User>;
  usersReadyForEventualNotifications: UserConnection;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryGlobalIdLookupArgs = {
  id: Scalars['String'];
  type: Scalars['String'];
};


export type QueryGlobalIdReverseLookupArgs = {
  id: Scalars['String'];
};


export type QueryFileNodeByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFolderByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFileByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFileContainerByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFolderContentsArgs = {
  input: GetContentsDownloadRequestInput;
};


export type QueryFilesBatchArgs = {
  input: GetBatchFileDownloadRequestInput;
};


export type QueryFileNodesArgs = {
  searchText?: Maybe<Scalars['String']>;
  filter?: Maybe<FileNodeFilterInput>;
  onlyTypes?: Maybe<Array<FileNodeType>>;
  sortBy?: Maybe<IFileNodesSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAssignableActorsForFileNodeArgs = {
  id: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryAssignableRolesForFileNodeArgs = {
  id: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryRecycledFileNodeByIdArgs = {
  id: Scalars['ID'];
};


export type QueryRecycledFolderByIdArgs = {
  id: Scalars['ID'];
};


export type QueryRecycledFileByIdArgs = {
  id: Scalars['ID'];
};


export type QueryRecycledFileContainerByIdArgs = {
  id: Scalars['ID'];
};


export type QueryRecycledFolderContentsArgs = {
  input: GetContentsDownloadRequestInput;
};


export type QueryRecycledFilesBatchArgs = {
  input: GetBatchFileDownloadRequestInput;
};


export type QueryRecycledFileNodesArgs = {
  searchText?: Maybe<Scalars['String']>;
  filter?: Maybe<RecycledFileNodeFilterInput>;
  onlyTypes?: Maybe<Array<FileNodeType>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryJobByIdArgs = {
  id: Scalars['ID'];
};


export type QueryJobByNameArgs = {
  name: Scalars['String'];
  projectId: Scalars['ID'];
};


export type QueryJobsByIdArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryJobsArgs = {
  searchText?: Maybe<Scalars['String']>;
  filter?: Maybe<JobsFilterInput>;
  sortBy?: Maybe<JobsSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryJobTemplateByIdArgs = {
  id: Scalars['ID'];
};


export type QueryMilestoneByIdArgs = {
  id: Scalars['ID'];
};


export type QueryMilestonesArgs = {
  searchText?: Maybe<Scalars['String']>;
  filter?: Maybe<MilestonesFilterInput>;
  sortBy?: Maybe<MilestonesSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryNotificationsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  priority?: Maybe<UserNofificationsPriorityFilter>;
  notified?: Maybe<UserNotificationsNotifiedFilter>;
};


export type QueryNotificationsForUsersArgs = {
  users: Array<Scalars['ID']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  priority?: Maybe<UserNofificationsPriorityFilter>;
  notified?: Maybe<UserNotificationsNotifiedFilter>;
};


export type QueryMyNotificationsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  read?: Maybe<UserNotificationsReadFilter>;
};


export type QueryOrganizationByIdArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationMembershipByIdArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationsArgs = {
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<OrganizationsSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryProjectByIdArgs = {
  id: Scalars['ID'];
};


export type QueryProjectByNameArgs = {
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['ID']>;
};


export type QueryProjectsArgs = {
  searchText?: Maybe<Scalars['String']>;
  filter?: Maybe<ProjectsFilterInput>;
  sortBy?: Maybe<ProjectsSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryProjectTemplateByIdArgs = {
  id: Scalars['ID'];
};


export type QueryTemplateRepositoryByIdArgs = {
  id: Scalars['ID'];
};


export type QueryTemplateRepositoryBySourceArgs = {
  source: Scalars['String'];
};


export type QueryTemplateRepositoriesArgs = {
  searchText?: Maybe<Scalars['String']>;
  filter?: Maybe<TemplateRepositoriesFilterInput>;
  sortBy?: Maybe<TemplateRepositoriesSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryTeamByIdArgs = {
  id: Scalars['ID'];
};


export type QueryTeamsArgs = {
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<TeamsSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryTeamMembersArgs = {
  sortBy?: Maybe<TeamMembersSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryApplicationByIdArgs = {
  id: Scalars['ID'];
};


export type QueryApplicationsArgs = {
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<ApplicationsSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryUserByIdArgs = {
  id: Scalars['ID'];
};


export type QueryUserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryUsersArgs = {
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<UsersSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryUsersByIdArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryUsersReadyForEventualNotificationsArgs = {
  lastNotificationTime?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type Node = {
  id: Scalars['ID'];
};

export type GlobalIdLookup = {
  __typename: 'GlobalIdLookup';
  globalId: Scalars['String'];
};

export type GlobalIdReverseLookup = {
  __typename: 'GlobalIdReverseLookup';
  id: Scalars['String'];
  type: Scalars['String'];
};

/** Request a server diagnostic response to check server and database connectivity. */
export type OnlineCheck = {
  __typename: 'OnlineCheck';
  /** Currennt timestamp */
  now?: Maybe<Scalars['String']>;
  db?: Maybe<Scalars['Boolean']>;
  files?: Maybe<Scalars['Boolean']>;
};

export type FileTypeConstraint = Node & {
  __typename: 'FileTypeConstraint';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /**
   * Restrict the size of individual files uploaded.
   * Does not cap total size of all files.
   */
  maxFileSize?: Maybe<Scalars['BigInt']>;
  whitelistExtensions?: Maybe<Array<Scalars['String']>>;
};


export type IFileNode = {
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  parent?: Maybe<Folder>;
  path: Scalars['String'];
  fullPath: Scalars['String'];
  pathIds: Array<Scalars['ID']>;
  depth: Scalars['Int'];
  fileNodeType: FileNodeType;
  /** A locked FileNode will most types of edits, including recycling/deletion. */
  locked: FileNodeLock;
  /**
   * Indicates the FileNode causing the locked state.
   * If locked = SELF, this will always by this file node.
   * Othwerwise it will a parent Folder, or possibly FileContainer in the case of a FileSubmission.
   */
  lockedBy?: Maybe<IFileNode>;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  hasPermissions?: Maybe<PermissionMap>;
};


export type IFileNodeCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type IFileNodeUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type Folder = Node & IFileNode & ISharableFileNode & {
  __typename: 'Folder';
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  parent?: Maybe<Folder>;
  path: Scalars['String'];
  fullPath: Scalars['String'];
  pathIds: Array<Scalars['ID']>;
  depth: Scalars['Int'];
  fileNodeType: FileNodeType;
  locked: FileNodeLock;
  lockedBy?: Maybe<IFileNode>;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  hasPermissions?: Maybe<PermissionMap>;
  /**
   * Allow files to be uploaded directly to the folder.
   * If false, files may only be uploaded as FileSubmissions to FileContainers in the folder.
   * Only applies to the current folder, not child folders.
   */
  allowFiles?: Maybe<Scalars['Boolean']>;
  /**
   * The maximum number of files allowed to be upload the folder.
   * A value of 0 removes the limit.
   */
  maxAllowed?: Maybe<Scalars['Int']>;
  /**
   * Maximum allowed file size per file in Bytes.
   * The maximum that may be uploaded to a folder is maxAllowed x maxFileSize.
   * If maxFileSize is 0 (unlimited), then there is no strict limit of the total size of the contents of the folder.
   */
  maxFileSize: Scalars['BigInt'];
  /** Fetches the */
  children: IFileNodeConnection;
  assignableActors?: Maybe<IActorConnection>;
  assignableRoles?: Maybe<FileRoleConnection>;
  members?: Maybe<FileNodeMembershipConnection>;
  blocked?: Maybe<FileNodeMembershipConnection>;
  effectiveAccess?: Maybe<IActorConnection>;
  currentOrganizationMembership?: Maybe<FileNodeMembership>;
  collaborators?: Maybe<FileNodeOrganizationCollaboratorConnection>;
};


export type FolderCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type FolderUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type FolderChildrenArgs = {
  searchText?: Maybe<Scalars['String']>;
  filter?: Maybe<FileNodeFilterInput>;
  depthMode?: Maybe<FileTreeDepthMode>;
  onlyTypes?: Maybe<Array<FileNodeType>>;
  sortBy?: Maybe<IFileNodesSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
};


export type FolderAssignableActorsArgs = {
  searchText?: Maybe<Scalars['String']>;
  onlyTypes?: Maybe<Array<ActorType>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type FolderAssignableRolesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type FolderMembersArgs = {
  searchText?: Maybe<Scalars['String']>;
  onlyTypes?: Maybe<Array<ActorType>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type FolderBlockedArgs = {
  searchText?: Maybe<Scalars['String']>;
  onlyTypes?: Maybe<Array<ActorType>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type FolderEffectiveAccessArgs = {
  searchText?: Maybe<Scalars['String']>;
  onlyTypes?: Maybe<Array<ActorType>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type FolderCollaboratorsArgs = {
  searchText?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

/**
 * Represents a FileNode that may be individually shared with others.
 * Folders grant access to all child FileNodes.
 * The waterfall access can be blocked by assigning the special block role.
 * FileContainers grant access to all of its FileSubmissions, why may not be individually managed.
 */
export type ISharableFileNode = {
  /** List the existing actors that may be granted a role on the node by the current user. */
  assignableActors?: Maybe<IActorConnection>;
  /** List the existing roles that may be assigned to the node by the current user. */
  assignableRoles?: Maybe<FileRoleConnection>;
  /** Specific actors with access to a node and its children (if applicable) */
  members?: Maybe<FileNodeMembershipConnection>;
  /** Specific actors whose access has been blocked from being inherited from parent folders. */
  blocked?: Maybe<FileNodeMembershipConnection>;
  effectiveAccess?: Maybe<IActorConnection>;
  /**
   * The membership of the current tenant ID, if provided.
   * Invalid query if tenant id header is not provided.
   */
  currentOrganizationMembership?: Maybe<FileNodeMembership>;
  /**
   * Organizations with shared access to the node.
   * 
   * Does not automatically grant access to any actors in the shared organization.
   * A user with appropriate permissions will need to add member actors first.
   */
  collaborators?: Maybe<FileNodeOrganizationCollaboratorConnection>;
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  parent?: Maybe<Folder>;
  path: Scalars['String'];
  fullPath: Scalars['String'];
  pathIds: Array<Scalars['ID']>;
  depth: Scalars['Int'];
  fileNodeType: FileNodeType;
  locked: FileNodeLock;
  lockedBy?: Maybe<IFileNode>;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  hasPermissions?: Maybe<PermissionMap>;
};


/**
 * Represents a FileNode that may be individually shared with others.
 * Folders grant access to all child FileNodes.
 * The waterfall access can be blocked by assigning the special block role.
 * FileContainers grant access to all of its FileSubmissions, why may not be individually managed.
 */
export type ISharableFileNodeAssignableActorsArgs = {
  searchText?: Maybe<Scalars['String']>;
  onlyTypes?: Maybe<Array<ActorType>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/**
 * Represents a FileNode that may be individually shared with others.
 * Folders grant access to all child FileNodes.
 * The waterfall access can be blocked by assigning the special block role.
 * FileContainers grant access to all of its FileSubmissions, why may not be individually managed.
 */
export type ISharableFileNodeAssignableRolesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/**
 * Represents a FileNode that may be individually shared with others.
 * Folders grant access to all child FileNodes.
 * The waterfall access can be blocked by assigning the special block role.
 * FileContainers grant access to all of its FileSubmissions, why may not be individually managed.
 */
export type ISharableFileNodeMembersArgs = {
  searchText?: Maybe<Scalars['String']>;
  onlyTypes?: Maybe<Array<ActorType>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/**
 * Represents a FileNode that may be individually shared with others.
 * Folders grant access to all child FileNodes.
 * The waterfall access can be blocked by assigning the special block role.
 * FileContainers grant access to all of its FileSubmissions, why may not be individually managed.
 */
export type ISharableFileNodeBlockedArgs = {
  searchText?: Maybe<Scalars['String']>;
  onlyTypes?: Maybe<Array<ActorType>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/**
 * Represents a FileNode that may be individually shared with others.
 * Folders grant access to all child FileNodes.
 * The waterfall access can be blocked by assigning the special block role.
 * FileContainers grant access to all of its FileSubmissions, why may not be individually managed.
 */
export type ISharableFileNodeEffectiveAccessArgs = {
  searchText?: Maybe<Scalars['String']>;
  onlyTypes?: Maybe<Array<ActorType>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/**
 * Represents a FileNode that may be individually shared with others.
 * Folders grant access to all child FileNodes.
 * The waterfall access can be blocked by assigning the special block role.
 * FileContainers grant access to all of its FileSubmissions, why may not be individually managed.
 */
export type ISharableFileNodeCollaboratorsArgs = {
  searchText?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/**
 * Represents a FileNode that may be individually shared with others.
 * Folders grant access to all child FileNodes.
 * The waterfall access can be blocked by assigning the special block role.
 * FileContainers grant access to all of its FileSubmissions, why may not be individually managed.
 */
export type ISharableFileNodeCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


/**
 * Represents a FileNode that may be individually shared with others.
 * Folders grant access to all child FileNodes.
 * The waterfall access can be blocked by assigning the special block role.
 * FileContainers grant access to all of its FileSubmissions, why may not be individually managed.
 */
export type ISharableFileNodeUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};

export enum ActorType {
  Organization = 'ORGANIZATION',
  Team = 'TEAM',
  User = 'USER'
}

/** Look up IActor Listings */
export type IActorConnection = {
  __typename: 'IActorConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IActorEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type IActorEdge = {
  __typename: 'IActorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<IActor>;
};

/**
 * Represents an assignable actor in the system.
 * One of:
 * * A single user
 * * The members of a team
 * * The members of an organization
 */
export type IActor = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** Look up FileRole Listings */
export type FileRoleConnection = {
  __typename: 'FileRoleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FileRoleEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type FileRoleEdge = {
  __typename: 'FileRoleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<FileRole>;
};

export type FileRole = Node & {
  __typename: 'FileRole';
  id: Scalars['ID'];
  name: Scalars['String'];
  hasPermissions?: Maybe<PermissionMap>;
  permissions?: Maybe<Array<Maybe<Permission>>>;
};

export type PermissionMap = {
  __typename: 'PermissionMap';
  organization_data_create?: Maybe<Scalars['Boolean']>;
  organization_data_read?: Maybe<Scalars['Boolean']>;
  organization_data_update?: Maybe<Scalars['Boolean']>;
  organization_data_delete?: Maybe<Scalars['Boolean']>;
  /**
   * Grants the user full administrator rights within the organization.
   * Only full admins may grant or revoke this permission or roles
   * containing this permission.
   */
  organization_admin_all?: Maybe<Scalars['Boolean']>;
  organization_members_manage?: Maybe<Scalars['Boolean']>;
  organization_members_read?: Maybe<Scalars['Boolean']>;
  team_data_create?: Maybe<Scalars['Boolean']>;
  team_data_read?: Maybe<Scalars['Boolean']>;
  team_data_update?: Maybe<Scalars['Boolean']>;
  team_data_delete?: Maybe<Scalars['Boolean']>;
  team_members_manage?: Maybe<Scalars['Boolean']>;
  team_members_read?: Maybe<Scalars['Boolean']>;
  file_data_create?: Maybe<Scalars['Boolean']>;
  file_data_read?: Maybe<Scalars['Boolean']>;
  file_data_update?: Maybe<Scalars['Boolean']>;
  file_data_recycle?: Maybe<Scalars['Boolean']>;
  file_data_delete?: Maybe<Scalars['Boolean']>;
  file_members_manage?: Maybe<Scalars['Boolean']>;
  file_members_read?: Maybe<Scalars['Boolean']>;
  /**
   * Designates the actor as the owner of the file_node and its children
   * (until reaching another node with an owner). Only one owner is
   * permitted at a time.
   */
  file_data_owner?: Maybe<Scalars['Boolean']>;
  /** Allow user to recycle files they uploaded */
  file_data_recycleown?: Maybe<Scalars['Boolean']>;
  /** Allow user to delete files they uploaded */
  file_data_deleteown?: Maybe<Scalars['Boolean']>;
  /** Allow user to submit file containers */
  file_status_submit?: Maybe<Scalars['Boolean']>;
  /** Allow user to cancel file containers */
  file_status_na?: Maybe<Scalars['Boolean']>;
  /** Allow user to reject file containers */
  file_status_reject?: Maybe<Scalars['Boolean']>;
  /** Allow user to approve and unapprove file containers */
  file_status_approve?: Maybe<Scalars['Boolean']>;
  /** Allow user to lock and unlock files and file submissions */
  file_lock_file?: Maybe<Scalars['Boolean']>;
  /** Allow user to lock and unlock file containers */
  file_lock_fileContainer?: Maybe<Scalars['Boolean']>;
  /** Allow user to lock and unlock folders */
  file_lock_folder?: Maybe<Scalars['Boolean']>;
  /** Allow user to see all files in the recycle bin */
  file_recycle_read?: Maybe<Scalars['Boolean']>;
  /** Allow user to see all files in the recycle bin */
  file_recycle_readown?: Maybe<Scalars['Boolean']>;
  /** Allow user to rename all files in the recycle bin */
  file_recycle_update?: Maybe<Scalars['Boolean']>;
  /** Allow user to rename all files in the recycle bin */
  file_recycle_updateown?: Maybe<Scalars['Boolean']>;
  /** Allow user to rename all files in the recycle bin */
  file_recycle_recover?: Maybe<Scalars['Boolean']>;
  /** Allow user to rename all files in the recycle bin */
  file_recycle_recoverown?: Maybe<Scalars['Boolean']>;
  /** Allow user to delete all files in the recycle bin */
  file_recycle_delete?: Maybe<Scalars['Boolean']>;
  /** Allow user to delete all files in the recycle bin */
  file_recycle_deleteown?: Maybe<Scalars['Boolean']>;
  project_data_create?: Maybe<Scalars['Boolean']>;
  project_data_read?: Maybe<Scalars['Boolean']>;
  project_data_update?: Maybe<Scalars['Boolean']>;
  project_data_delete?: Maybe<Scalars['Boolean']>;
  project_template_create?: Maybe<Scalars['Boolean']>;
  project_template_read?: Maybe<Scalars['Boolean']>;
  project_template_update?: Maybe<Scalars['Boolean']>;
  project_template_delete?: Maybe<Scalars['Boolean']>;
  project_members_manage?: Maybe<Scalars['Boolean']>;
  project_members_read?: Maybe<Scalars['Boolean']>;
  /** Allow user to reset projects to planned */
  project_status_planned?: Maybe<Scalars['Boolean']>;
  /** Allow user to set projects to active */
  project_status_active?: Maybe<Scalars['Boolean']>;
  /** Allow user to cancel projects */
  project_status_cancel?: Maybe<Scalars['Boolean']>;
  /** Allow user to complete projects */
  project_status_complete?: Maybe<Scalars['Boolean']>;
  /** Allow user to (un)archive projects */
  project_status_archive?: Maybe<Scalars['Boolean']>;
  job_data_create?: Maybe<Scalars['Boolean']>;
  job_data_read?: Maybe<Scalars['Boolean']>;
  job_data_update?: Maybe<Scalars['Boolean']>;
  job_data_delete?: Maybe<Scalars['Boolean']>;
  job_template_create?: Maybe<Scalars['Boolean']>;
  job_template_read?: Maybe<Scalars['Boolean']>;
  job_template_update?: Maybe<Scalars['Boolean']>;
  job_template_delete?: Maybe<Scalars['Boolean']>;
  job_members_manage?: Maybe<Scalars['Boolean']>;
  job_members_read?: Maybe<Scalars['Boolean']>;
  /** Allow user to reset jobs to planned */
  job_status_planned?: Maybe<Scalars['Boolean']>;
  /** Allow user to set jobs to active */
  job_status_active?: Maybe<Scalars['Boolean']>;
  /** Allow user to cancel jobs */
  job_status_cancel?: Maybe<Scalars['Boolean']>;
  /** Allow user to complete jobs */
  job_status_complete?: Maybe<Scalars['Boolean']>;
  /** Allow user to (un)archive jobs */
  job_status_archive?: Maybe<Scalars['Boolean']>;
  milestone_data_create?: Maybe<Scalars['Boolean']>;
  milestone_data_read?: Maybe<Scalars['Boolean']>;
  milestone_data_update?: Maybe<Scalars['Boolean']>;
  milestone_data_delete?: Maybe<Scalars['Boolean']>;
  /** Allow user to submit milestones for review */
  milestone_status_submit?: Maybe<Scalars['Boolean']>;
  /** Allow user to cancel milestones */
  milestone_status_na?: Maybe<Scalars['Boolean']>;
  /** Allow user to review milestones */
  milestone_status_review?: Maybe<Scalars['Boolean']>;
  /** Allow user to approve or unapprove milestones */
  milestone_status_approve?: Maybe<Scalars['Boolean']>;
  template_data_create?: Maybe<Scalars['Boolean']>;
  template_data_read?: Maybe<Scalars['Boolean']>;
  template_data_update?: Maybe<Scalars['Boolean']>;
  template_data_delete?: Maybe<Scalars['Boolean']>;
};

export type Permission = Node & {
  __typename: 'Permission';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

/** Look up FileNodeMembership Listings */
export type FileNodeMembershipConnection = {
  __typename: 'FileNodeMembershipConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FileNodeMembershipEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type FileNodeMembershipEdge = {
  __typename: 'FileNodeMembershipEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<FileNodeMembership>;
};

/** Grant an actor access to a FileNode with a specified role. */
export type FileNodeMembership = {
  __typename: 'FileNodeMembership';
  /**
   * TODO: finalize design.
   *  do we explicitly allow granting multiple roles?
   *  If so, create multiple memberships or make fileRole an array?
   *  In case of assigning 'nothing', just find all same node, same actor memberships and delete them?
   */
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  fileNodeId: Scalars['ID'];
  fileNode: ISharableFileNode;
  actorId: Scalars['ID'];
  actor?: Maybe<IActor>;
  fileRoleId: Scalars['ID'];
  fileRole: FileRole;
  grantedById: Scalars['ID'];
  grantedBy: Organization;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
};


/** Grant an actor access to a FileNode with a specified role. */
export type FileNodeMembershipCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};

/** A multi-user tenant of the service. */
export type Organization = Node & IActor & {
  __typename: 'Organization';
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  /** Unique name used in public Template Module resolution. */
  codeName?: Maybe<Scalars['String']>;
  folderId: Scalars['ID'];
  folder: Folder;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  hasPermissions?: Maybe<PermissionMap>;
  assignableRoles?: Maybe<OrganizationRoleConnection>;
  members: OrganizationMembershipConnection;
  projects: ProjectConnection;
  /** List of all templates the organizations has created. */
  templateRepositories: TemplateRepositoryConnection;
  teams: TeamConnection;
};


/** A multi-user tenant of the service. */
export type OrganizationCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


/** A multi-user tenant of the service. */
export type OrganizationUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


/** A multi-user tenant of the service. */
export type OrganizationAssignableRolesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/** A multi-user tenant of the service. */
export type OrganizationMembersArgs = {
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<OrganizationMembershipsSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/** A multi-user tenant of the service. */
export type OrganizationProjectsArgs = {
  searchText?: Maybe<Scalars['String']>;
  filter?: Maybe<ProjectsFilterInput>;
  sortBy?: Maybe<ProjectsSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/** A multi-user tenant of the service. */
export type OrganizationTemplateRepositoriesArgs = {
  searchText?: Maybe<Scalars['String']>;
  filter?: Maybe<TemplateRepositoriesFilterInput>;
  sortBy?: Maybe<TemplateRepositoriesSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/** A multi-user tenant of the service. */
export type OrganizationTeamsArgs = {
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<TeamsSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type IUser = {
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};


export type IUserCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type IUserUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};

/** Customize date outputs. */
export type DateFormatInput = {
  /**
   * The formatting string for the date field.
   * See moment.js documentation for available formatting options.
   * Additionally, "ISO" may be used to get an ISO 8601 formatted string.
   */
  formatString?: Maybe<Scalars['String']>;
  /**
   * Specify a different timezone for the date.
   * By default, the timezone is obtained from the request information and used for all dates.
   */
  timezone?: Maybe<Scalars['IanaTimezone']>;
};


/** Look up OrganizationRole Listings */
export type OrganizationRoleConnection = {
  __typename: 'OrganizationRoleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrganizationRoleEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type OrganizationRoleEdge = {
  __typename: 'OrganizationRoleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<OrganizationRole>;
};

export type OrganizationRole = Node & {
  __typename: 'OrganizationRole';
  id: Scalars['ID'];
  name: Scalars['String'];
  hasPermissions?: Maybe<PermissionMap>;
  permissions?: Maybe<Array<Maybe<Permission>>>;
};

export type OrganizationMembershipsSortStrategy = {
  key?: Maybe<OrganizationMembershipsSortField>;
  order?: Maybe<DirectionEnum>;
};

export enum OrganizationMembershipsSortField {
  Id = 'ID',
  UserName = 'USER_NAME',
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

export enum DirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Look up OrganizationMembership Listings */
export type OrganizationMembershipConnection = {
  __typename: 'OrganizationMembershipConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrganizationMembershipEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type OrganizationMembershipEdge = {
  __typename: 'OrganizationMembershipEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<OrganizationMembership>;
};

export type OrganizationMembership = Node & {
  __typename: 'OrganizationMembership';
  id: Scalars['ID'];
  userId: Scalars['ID'];
  user: User;
  organizationId: Scalars['ID'];
  organization: Organization;
  roleId: Scalars['ID'];
  role: OrganizationRole;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  fileRoles?: Maybe<FileNodeMembershipConnection>;
  jobRoles?: Maybe<FileNodeMembershipConnection>;
  projectRoles?: Maybe<FileNodeMembershipConnection>;
  /** All teams this user is a member of in the organization. */
  teams: TeamConnection;
};


export type OrganizationMembershipCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type OrganizationMembershipUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type OrganizationMembershipFileRolesArgs = {
  searchText?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type OrganizationMembershipJobRolesArgs = {
  searchText?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type OrganizationMembershipProjectRolesArgs = {
  searchText?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type OrganizationMembershipTeamsArgs = {
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<TeamsSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

/** A human user of the service. */
export type User = Node & IUser & IActor & {
  __typename: 'User';
  id: Scalars['ID'];
  email: Scalars['String'];
  name: Scalars['String'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  notificationConfig?: Maybe<UserNotificationConfig>;
  lastEventualNotification?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  organizations: OrganizationConnection;
  /** Retrieve all teams the user is a member of. */
  teams: TeamConnection;
};


/** A human user of the service. */
export type UserLastEventualNotificationArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


/** A human user of the service. */
export type UserCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


/** A human user of the service. */
export type UserUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


/** A human user of the service. */
export type UserOrganizationsArgs = {
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<OrganizationsSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/** A human user of the service. */
export type UserTeamsArgs = {
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<TeamsSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type UserNotificationConfig = {
  __typename: 'UserNotificationConfig';
  default?: Maybe<UserNotificationTopicConfig>;
  topics?: Maybe<Array<UserNotificationTopicAndConfig>>;
};

export type UserNotificationTopicConfig = {
  __typename: 'UserNotificationTopicConfig';
  dest: Array<UserNotificationDest>;
};

export enum UserNotificationDest {
  Email = 'EMAIL'
}

export type UserNotificationTopicAndConfig = {
  __typename: 'UserNotificationTopicAndConfig';
  topic: UserNotificationTopic;
  config?: Maybe<UserNotificationTopicConfig>;
};

export enum UserNotificationTopic {
  MsDone = 'MS_DONE',
  MsApproved = 'MS_APPROVED',
  MsRejected = 'MS_REJECTED'
}

export type OrganizationsSortStrategy = {
  key?: Maybe<OrganizationsSortField>;
  order?: Maybe<DirectionEnum>;
};

export enum OrganizationsSortField {
  Id = 'ID',
  Name = 'NAME',
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Look up Organization Listings */
export type OrganizationConnection = {
  __typename: 'OrganizationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrganizationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type OrganizationEdge = {
  __typename: 'OrganizationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Organization>;
};

export type TeamsSortStrategy = {
  key?: Maybe<TeamsSortField>;
  order?: Maybe<DirectionEnum>;
};

export enum TeamsSortField {
  Id = 'ID',
  Name = 'NAME',
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Look up Team Listings */
export type TeamConnection = {
  __typename: 'TeamConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TeamEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type TeamEdge = {
  __typename: 'TeamEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Team>;
};

/** A group of users that can be treated as a single actor. */
export type Team = Node & IActor & {
  __typename: 'Team';
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  organization: Organization;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  avatars: Array<TeamAvatar>;
  /** Current members of the team. */
  members: UserConnection;
  fileRoles?: Maybe<FileNodeMembershipConnection>;
  jobRoles?: Maybe<FileNodeMembershipConnection>;
  projectRoles?: Maybe<FileNodeMembershipConnection>;
};


/** A group of users that can be treated as a single actor. */
export type TeamCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


/** A group of users that can be treated as a single actor. */
export type TeamUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


/** A group of users that can be treated as a single actor. */
export type TeamMembersArgs = {
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<UsersSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/** A group of users that can be treated as a single actor. */
export type TeamFileRolesArgs = {
  searchText?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/** A group of users that can be treated as a single actor. */
export type TeamJobRolesArgs = {
  searchText?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/** A group of users that can be treated as a single actor. */
export type TeamProjectRolesArgs = {
  searchText?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type TeamAvatar = {
  __typename: 'TeamAvatar';
  id: Scalars['ID'];
  name: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
};

export type UsersSortStrategy = {
  key?: Maybe<UsersSortField>;
  order?: Maybe<DirectionEnum>;
};

export enum UsersSortField {
  Id = 'ID',
  Name = 'NAME',
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Look up User Listings */
export type UserConnection = {
  __typename: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export type ProjectsFilterInput = {
  /**
   * Archived projects are excluded by default.
   * Pass true to include them in the results.
   */
  includeArchived?: Maybe<Scalars['Boolean']>;
  /** If provided and not empty, filters the results on only the specified statuses. */
  status?: Maybe<Array<ProjectStatus>>;
};

export enum ProjectStatus {
  Planned = 'PLANNED',
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE'
}

export type ProjectsSortStrategy = {
  key?: Maybe<ProjectsSortField>;
  order?: Maybe<DirectionEnum>;
};

export enum ProjectsSortField {
  Id = 'ID',
  Name = 'NAME',
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Look up Project Listings */
export type ProjectConnection = {
  __typename: 'ProjectConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProjectEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type ProjectEdge = {
  __typename: 'ProjectEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Project>;
};

/** A project within an organization. Manages a collection of jobs with shared configuration. */
export type Project = Node & {
  __typename: 'Project';
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  status: ProjectStatus;
  archived: Scalars['Boolean'];
  /** TODO: query job bounding box for default map location and zoom level. */
  organizationId: Scalars['ID'];
  organization: Organization;
  folderId: Scalars['ID'];
  folder: Folder;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  hasPermissions?: Maybe<PermissionMap>;
  /** Total number of jobs in the project. */
  totalJobs: Scalars['Int'];
  jobs: JobConnection;
  /**
   * Retrieve Jobs with location in a map bounding box and clustered to a specified zoom level.
   * Paging is not supported.
   * Clustered results should keep page size manageable.
   */
  jobsClusteredByLocation?: Maybe<Array<Job>>;
  /** Paginated list of jobs filtered on a location and map bounding box. */
  jobsByLocation: JobConnection;
  templates: ProjectTemplateConnection;
  jobTemplates: JobTemplateConnection;
};


/** A project within an organization. Manages a collection of jobs with shared configuration. */
export type ProjectCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


/** A project within an organization. Manages a collection of jobs with shared configuration. */
export type ProjectUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


/** A project within an organization. Manages a collection of jobs with shared configuration. */
export type ProjectJobsArgs = {
  searchText?: Maybe<Scalars['String']>;
  filter?: Maybe<JobsFilterInput>;
  sortBy?: Maybe<JobsSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/** A project within an organization. Manages a collection of jobs with shared configuration. */
export type ProjectJobsClusteredByLocationArgs = {
  location?: Maybe<Scalars['Geography']>;
};


/** A project within an organization. Manages a collection of jobs with shared configuration. */
export type ProjectJobsByLocationArgs = {
  searchText?: Maybe<Scalars['String']>;
  filter?: Maybe<JobsFilterInput>;
  bounds?: Maybe<GeometryBoundingBox>;
  sortBy?: Maybe<JobsSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/** A project within an organization. Manages a collection of jobs with shared configuration. */
export type ProjectTemplatesArgs = {
  searchText?: Maybe<Scalars['String']>;
  filter?: Maybe<ProjectTemplateFilterInput>;
  sortBy?: Maybe<ProjectTemplatesSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/** A project within an organization. Manages a collection of jobs with shared configuration. */
export type ProjectJobTemplatesArgs = {
  searchText?: Maybe<Scalars['String']>;
  filter?: Maybe<JobTemplateFilterInput>;
  sortBy?: Maybe<JobTemplatesSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type JobsFilterInput = {
  /**
   * Archived projects are excluded by default.
   * Pass true to include them in the results.
   */
  includeArchived?: Maybe<Scalars['Boolean']>;
  /** If provided and not empty, filters the results on only the specified statuses. */
  status?: Maybe<Array<ProjectStatus>>;
  /** Only includes jobs where the startDate matches the provided range filter. */
  startDate?: Maybe<DateTimeRangeFilter>;
  /** Only includes jobs where the endDate matches the provided range filter. */
  endDate?: Maybe<DateTimeRangeFilter>;
};

/**
 * This input allows a date to be filtered with an open or closed date range.
 * The date format "YYYY-MM-DDThh:mm:ssZ" does not support the zone prop and it will be ignored.
 * 
 * If a date range is provided, results with no date will be excluded.
 */
export type DateTimeRangeFilter = {
  /**
   * Use none: true to only return results with no date value.
   * If true is provided, all other props in the filter will be ignored.
   */
  none?: Maybe<Scalars['Boolean']>;
  after?: Maybe<Scalars['ISODateOptionalTime']>;
  before?: Maybe<Scalars['ISODateOptionalTime']>;
  /**
   * Specify a named time zone for the date.
   * If not specified, UTC is used.
   */
  zone?: Maybe<Scalars['IanaTimezone']>;
};


export type JobsSortStrategy = {
  key?: Maybe<JobsSortField>;
  order?: Maybe<DirectionEnum>;
};

export enum JobsSortField {
  Id = 'ID',
  Name = 'NAME',
  JobNumber = 'JOB_NUMBER',
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Look up Job Listings */
export type JobConnection = {
  __typename: 'JobConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<JobEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type JobEdge = {
  __typename: 'JobEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Job>;
};

export type Job = Node & {
  __typename: 'Job';
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  jobNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  status: ProjectStatus;
  archived: Scalars['Boolean'];
  address?: Maybe<Scalars['String']>;
  /** Strips the city, state, & zip, showing only the street address. */
  addressShort?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['Geography']>;
  /** Job start date in MM/DD/YYYY format. */
  startDate?: Maybe<Scalars['String']>;
  /** Job end date in MM/DD/YYYY format. */
  endDate?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
  project: Project;
  folderId: Scalars['ID'];
  folder: Folder;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  hasPermissions?: Maybe<PermissionMap>;
  milestones: MilestoneConnection;
  templates: JobTemplateConnection;
};


export type JobCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type JobUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type JobMilestonesArgs = {
  searchText?: Maybe<Scalars['String']>;
  filter?: Maybe<MilestonesFilterInput>;
  sortBy?: Maybe<MilestonesSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type JobTemplatesArgs = {
  searchText?: Maybe<Scalars['String']>;
  filter?: Maybe<JobTemplateFilterInput>;
  sortBy?: Maybe<JobTemplatesSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type MilestonesFilterInput = {
  /** If provided and not empty, filters the results on only the specified statuses. */
  status?: Maybe<Array<MilestoneStatus>>;
};

export enum MilestoneStatus {
  Na = 'NA',
  Todo = 'TODO',
  AwaitingReview = 'AWAITING_REVIEW',
  UnderReview = 'UNDER_REVIEW',
  Approved = 'APPROVED'
}

export type MilestonesSortStrategy = {
  key?: Maybe<MilestonesSortField>;
  order?: Maybe<DirectionEnum>;
};

export enum MilestonesSortField {
  Id = 'ID',
  Name = 'NAME',
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Look up Milestone Listings */
export type MilestoneConnection = {
  __typename: 'MilestoneConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MilestoneEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type MilestoneEdge = {
  __typename: 'MilestoneEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Milestone>;
};

export type Milestone = Node & {
  __typename: 'Milestone';
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  status: MilestoneStatus;
  jobId: Scalars['ID'];
  job: Job;
  /** The number of tracked file containers that are still in the TODO status. */
  todosRemaining: Scalars['Int'];
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  hasPermissions?: Maybe<PermissionMap>;
  /**
   * The currently active review.
   * Will always be null when status != UNDER_REVIEW.
   * Will always be not null when status == UNDER_REVIEW.
   */
  activeReview?: Maybe<MilestoneReview>;
  /** Previews reviews of this Milestone. */
  reviews: MilestoneReviewConnection;
  /** The list of Folders and FileContainers the Milestone requires. */
  trackedFiles: IFileNodeConnection;
  /** All FileContainers and FileSubmissions associated with the trackedFiles. */
  files: IFileNodeConnection;
};


export type MilestoneCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type MilestoneUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type MilestoneReviewsArgs = {
  sortBy?: Maybe<MilestoneReviewsSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type MilestoneTrackedFilesArgs = {
  searchText?: Maybe<Scalars['String']>;
  onlyTypes?: Maybe<Array<FileNodeType>>;
  sortBy?: Maybe<IFileNodesSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type MilestoneFilesArgs = {
  searchText?: Maybe<Scalars['String']>;
  onlyTypes?: Maybe<Array<FileNodeType>>;
  filter?: Maybe<FileNodeFilterInput>;
  sortBy?: Maybe<IFileNodesSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type MilestoneReview = Node & {
  __typename: 'MilestoneReview';
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  active: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  milestoneId: Scalars['ID'];
  milestone: Milestone;
  startedById: Scalars['ID'];
  startedBy: User;
  startedAt: Scalars['String'];
  /** The status the milestone was changed to when this review was closed. */
  endedStatus?: Maybe<MilestoneStatus>;
  endedById?: Maybe<Scalars['ID']>;
  endedBy?: Maybe<User>;
  endedAt?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
};


export type MilestoneReviewStartedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type MilestoneReviewEndedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type MilestoneReviewUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};

export type MilestoneReviewsSortStrategy = {
  key?: Maybe<MilestoneReviewsSortField>;
  order?: Maybe<DirectionEnum>;
};

export enum MilestoneReviewsSortField {
  Id = 'ID',
  StartedAt = 'STARTED_AT',
  EndedAt = 'ENDED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Look up MilestoneReview Listings */
export type MilestoneReviewConnection = {
  __typename: 'MilestoneReviewConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MilestoneReviewEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type MilestoneReviewEdge = {
  __typename: 'MilestoneReviewEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<MilestoneReview>;
};

export enum FileNodeType {
  Folder = 'FOLDER',
  FileContainer = 'FILE_CONTAINER',
  FileSubmission = 'FILE_SUBMISSION',
  File = 'FILE'
}

export type IFileNodesSortStrategy = {
  key?: Maybe<IFileNodesSortField>;
  order?: Maybe<DirectionEnum>;
};

export enum IFileNodesSortField {
  Id = 'ID',
  Name = 'NAME',
  Size = 'SIZE',
  Path = 'PATH',
  IsFolder = 'IS_FOLDER',
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Look up IFileNode Listings */
export type IFileNodeConnection = {
  __typename: 'IFileNodeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IFileNodeEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type IFileNodeEdge = {
  __typename: 'IFileNodeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<IFileNode>;
};

export type FileNodeFilterInput = {
  /**
   * Filter results to omit files with an uploadedAt date based on the provided date range filter.
   * This filter ignores Folder and FileContainer types.
   */
  uploadedAt?: Maybe<DateTimeRangeFilter>;
  /**
   * Filter results to omit files not uploaded by the specified users.
   * This filter ignores Folder and FileContainer types.
   */
  uploadedBy?: Maybe<Array<Scalars['ID']>>;
  /** If provided and not empty, filters the results on only the specified statuses. */
  status?: Maybe<Array<FileNodeStatus>>;
};

export enum FileNodeStatus {
  Na = 'NA',
  Todo = 'TODO',
  Done = 'DONE',
  Approved = 'APPROVED',
  Rejected = 'REJECTED'
}

export type JobTemplateFilterInput = {
  initialized?: Maybe<BooleanFilter>;
};

export enum BooleanFilter {
  All = 'ALL',
  TrueOnly = 'TRUE_ONLY',
  FalseOnly = 'FALSE_ONLY'
}

export type JobTemplatesSortStrategy = {
  key?: Maybe<JobTemplatesSortField>;
  order?: Maybe<DirectionEnum>;
};

export enum JobTemplatesSortField {
  Id = 'ID',
  Name = 'NAME',
  Checksum = 'CHECKSUM',
  Version = 'VERSION',
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Look up JobTemplate Listings */
export type JobTemplateConnection = {
  __typename: 'JobTemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<JobTemplateEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type JobTemplateEdge = {
  __typename: 'JobTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<JobTemplate>;
};

export type JobTemplate = Node & ITemplate & {
  __typename: 'JobTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  source: Scalars['String'];
  checksum: Scalars['String'];
  version?: Maybe<Scalars['String']>;
  appliedSource?: Maybe<Scalars['String']>;
  appliedChecksum?: Maybe<Scalars['String']>;
  appliedVersion?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fs: Scalars['JSONObject'];
  /** Whether first initialization of state and form data has been completed. */
  initialized: Scalars['Boolean'];
  /** Current template state. */
  state: Scalars['JSONObject'];
  /** Most recently applied template variables. */
  variables: Scalars['JSONObject'];
  lockedById?: Maybe<Scalars['ID']>;
  lockedBy?: Maybe<IUser>;
  lockedAt?: Maybe<Scalars['String']>;
  jobId: Scalars['ID'];
  job: Job;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};


export type JobTemplateLockedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type JobTemplateCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type JobTemplateUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};

export type ITemplate = {
  id: Scalars['ID'];
  name: Scalars['String'];
  source: Scalars['String'];
  checksum: Scalars['String'];
  version?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /**
   * Template file system in JSON format.
   * Only UTF-8 text files and directories are supported.
   * Names are not case-sensitive but changes will impact checksum.
   */
  fs: Scalars['JSONObject'];
};


export type GeometryBoundingBox = {
  top: Scalars['Float'];
  left: Scalars['Float'];
  bottom: Scalars['Float'];
  right: Scalars['Float'];
};

export type ProjectTemplateFilterInput = {
  inclusion?: Maybe<RepositoryVisibility>;
};

export enum RepositoryVisibility {
  All = 'ALL',
  PublicOnly = 'PUBLIC_ONLY',
  PrivateOnly = 'PRIVATE_ONLY'
}

export type ProjectTemplatesSortStrategy = {
  key?: Maybe<ProjectTemplatesSortField>;
  order?: Maybe<DirectionEnum>;
};

export enum ProjectTemplatesSortField {
  Id = 'ID',
  Name = 'NAME',
  Checksum = 'CHECKSUM',
  Version = 'VERSION',
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Look up ProjectTemplate Listings */
export type ProjectTemplateConnection = {
  __typename: 'ProjectTemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProjectTemplateEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type ProjectTemplateEdge = {
  __typename: 'ProjectTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProjectTemplate>;
};

export type ProjectTemplate = Node & ITemplate & {
  __typename: 'ProjectTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  source: Scalars['String'];
  checksum: Scalars['String'];
  version?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fs: Scalars['JSONObject'];
  /** If false, this template will be added to all jobs automatically. */
  suggestion: Scalars['Boolean'];
  projectId: Scalars['ID'];
  project: Project;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};


export type ProjectTemplateCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type ProjectTemplateUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};

export type TemplateRepositoriesFilterInput = {
  visibility?: Maybe<RepositoryVisibility>;
};

export type TemplateRepositoriesSortStrategy = {
  key?: Maybe<TemplateRepositoriesSortField>;
  order?: Maybe<DirectionEnum>;
};

export enum TemplateRepositoriesSortField {
  Id = 'ID',
  DisplayName = 'DISPLAY_NAME',
  ModuleName = 'MODULE_NAME',
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Look up TemplateRepository Listings */
export type TemplateRepositoryConnection = {
  __typename: 'TemplateRepositoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TemplateRepositoryEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type TemplateRepositoryEdge = {
  __typename: 'TemplateRepositoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TemplateRepository>;
};

/** A machine user of the service. */
export type TemplateRepository = Node & {
  __typename: 'TemplateRepository';
  id: Scalars['ID'];
  /**
   * URI for the template.
   * Use to reference in module source="" blocks.
   */
  source: Scalars['String'];
  /** Human friendly name of the template. */
  displayName: Scalars['String'];
  /** Code name of the module, used in module.source attributes. */
  moduleName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** If true, this repository will be publically available to use by all organizations. */
  public: Scalars['Boolean'];
  latestId?: Maybe<Scalars['ID']>;
  latest?: Maybe<TemplateCommit>;
  /** Uncommited draft of the current edits to the file system. */
  draft?: Maybe<Scalars['JSONObject']>;
  organizationId: Scalars['ID'];
  organization: Organization;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  commits: TemplateCommitConnection;
};


/** A machine user of the service. */
export type TemplateRepositoryCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


/** A machine user of the service. */
export type TemplateRepositoryUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


/** A machine user of the service. */
export type TemplateRepositoryCommitsArgs = {
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<TemplateCommitsSortStrategy>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type TemplateCommit = Node & {
  __typename: 'TemplateCommit';
  id: Scalars['ID'];
  repositoryId: Scalars['ID'];
  repository: TemplateRepository;
  /**
   * Optional named version for the current commit.
   * May be added or updated after the commit is created.
   */
  version?: Maybe<Scalars['String']>;
  /** SHA256 checksum of the fs data. */
  checksum: Scalars['String'];
  /** Commit message supports (Github flavored) Markdown formatted text. */
  message: Scalars['String'];
  /**
   * Template file system in JSON format.
   * Only UTF-8 text files and directories are supported.
   * Names are not case-sensitive but changes will impact checksum.
   * No edits are allowed after commit is created.
   */
  fs: Scalars['JSONObject'];
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};


export type TemplateCommitCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type TemplateCommitUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};

export type TemplateCommitsSortStrategy = {
  key?: Maybe<TemplateCommitsSortField>;
  order?: Maybe<DirectionEnum>;
};

export enum TemplateCommitsSortField {
  Id = 'ID',
  Version = 'VERSION',
  Checksum = 'CHECKSUM',
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Look up TemplateCommit Listings */
export type TemplateCommitConnection = {
  __typename: 'TemplateCommitConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TemplateCommitEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type TemplateCommitEdge = {
  __typename: 'TemplateCommitEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TemplateCommit>;
};

/** Look up FileNodeOrganizationCollaborator Listings */
export type FileNodeOrganizationCollaboratorConnection = {
  __typename: 'FileNodeOrganizationCollaboratorConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FileNodeOrganizationCollaboratorEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type FileNodeOrganizationCollaboratorEdge = {
  __typename: 'FileNodeOrganizationCollaboratorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<FileNodeOrganizationCollaborator>;
};

/**
 * Share access with another organization.
 * 
 * That organization may then grant access to their own members
 */
export type FileNodeOrganizationCollaborator = Node & {
  __typename: 'FileNodeOrganizationCollaborator';
  /**
   * TODO: should probably define a line of allowed roles and/or permissions that may be granted to their users/teams.
   * TODO: need to define whether they are allowed to share with other orgs.
   *   this might just be a standard permission, although that may complicate defining default roles.
   *   alt, a collab could a normal membership with a role, and that role would contain the add-new-collab permission or not.
   */
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  organizationId: Scalars['ID'];
  organization: Organization;
  fileRoleId: Scalars['ID'];
  fileRole: FileRole;
  grantedById: Scalars['ID'];
  grantedBy: Organization;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  /** TODO: connections and DB impl */
  grantableRoles?: Maybe<Array<FileRole>>;
  grantablePermissions?: Maybe<Array<Permission>>;
};


/**
 * Share access with another organization.
 * 
 * That organization may then grant access to their own members
 */
export type FileNodeOrganizationCollaboratorCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};

export enum FileNodeLock {
  Unlocked = 'UNLOCKED',
  Locked = 'LOCKED',
  /**
   * The FileNode is not directly locked but an ancestor Folder is locked.
   * If the node is a FileSubmission, it can also inherit a lock from its FileContainer.
   */
  LockedByAncestor = 'LOCKED_BY_ANCESTOR'
}

export enum FileTreeDepthMode {
  Direct = 'DIRECT',
  Flat = 'FLAT'
}

export type IFile = {
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  /** File mime type. */
  contentType?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  parent?: Maybe<Folder>;
  path: Scalars['String'];
  fullPath: Scalars['String'];
  pathIds: Array<Scalars['ID']>;
  depth: Scalars['Int'];
  fileNodeType: FileNodeType;
  locked: FileNodeLock;
  lockedBy?: Maybe<IFileNode>;
  uploading: Scalars['Boolean'];
  uploadProgress?: Maybe<Scalars['Float']>;
  /** File size in bytes. Only known after upload is complete. */
  size?: Maybe<Scalars['Int']>;
  /** File download URL. */
  url: Scalars['String'];
  /** File types without preview support will return null. */
  preview?: Maybe<FilePreview>;
  /** Photo Exif data if the file is a compatible photo with Exif data. */
  exif?: Maybe<Exif>;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};


export type IFilePreviewArgs = {
  options?: Maybe<FilePreviewOptions>;
};


export type IFileCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type IFileUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};

export type FilePreviewOptions = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type FilePreview = {
  __typename: 'FilePreview';
  /**
   * If the file type supports a preview image, returns a url to that image.
   * Multi-page document types will retrun a preview of the first page.
   */
  url?: Maybe<Scalars['String']>;
  /** Multi-page document types will return an array of URLs in page-order. */
  pages?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Supported exif properties from Jpeg files. */
export type Exif = {
  __typename: 'Exif';
  DateTimeOriginal?: Maybe<Scalars['String']>;
  Latitude?: Maybe<Scalars['Float']>;
  Longitude?: Maybe<Scalars['Float']>;
};

export type FileContainer = Node & IFileNode & ISharableFileNode & {
  __typename: 'FileContainer';
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  /** Describe the expected content of the uploaded file(s). */
  notes?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  parent?: Maybe<Folder>;
  path: Scalars['String'];
  fullPath: Scalars['String'];
  pathIds: Array<Scalars['ID']>;
  depth: Scalars['Int'];
  fileNodeType: FileNodeType;
  status: FileNodeStatus;
  locked: FileNodeLock;
  lockedBy?: Maybe<IFileNode>;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  completedSubmissions?: Maybe<Scalars['Int']>;
  hasPermissions?: Maybe<PermissionMap>;
  /**
   * When true, all uploaded FileSubmissions will be renamed to match the container name by default.
   * When false, uploaded FileSubmissions will keep their original file name.
   */
  renameFile?: Maybe<Scalars['Boolean']>;
  /** Recommended minimum number of files for the user to upload. */
  minSuggestion?: Maybe<Scalars['Int']>;
  /** Recommended maximum number of files for the user to upload. */
  maxSuggestion?: Maybe<Scalars['Int']>;
  /** Minimum number of files that must be uploaded before the container may be considered complete. */
  minRequired?: Maybe<Scalars['Int']>;
  /** The maximum number of file that may be uploaded to the folder. */
  maxAllowed?: Maybe<Scalars['Int']>;
  /** Maximum allowed file size in Bytes */
  maxFileSize: Scalars['BigInt'];
  /**
   * Whitelist of allowed file extensions that may be uploaded.
   * This property merges the custom whitelist with the whitelist of the inherited file type.
   */
  whitelistExtensions: Array<Scalars['String']>;
  /** Custom whitelist of allowed file extensions that may be uploaded. */
  customWhitelistExtensions: Array<Scalars['String']>;
  /** Selected standard file type to define file constraints. */
  inheritedFileType?: Maybe<Scalars['ID']>;
  assignableActors?: Maybe<IActorConnection>;
  assignableRoles?: Maybe<FileRoleConnection>;
  members?: Maybe<FileNodeMembershipConnection>;
  blocked?: Maybe<FileNodeMembershipConnection>;
  effectiveAccess?: Maybe<IActorConnection>;
  currentOrganizationMembership?: Maybe<FileNodeMembership>;
  collaborators?: Maybe<FileNodeOrganizationCollaboratorConnection>;
};


export type FileContainerCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type FileContainerUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type FileContainerAssignableActorsArgs = {
  searchText?: Maybe<Scalars['String']>;
  onlyTypes?: Maybe<Array<ActorType>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type FileContainerAssignableRolesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type FileContainerMembersArgs = {
  searchText?: Maybe<Scalars['String']>;
  onlyTypes?: Maybe<Array<ActorType>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type FileContainerBlockedArgs = {
  searchText?: Maybe<Scalars['String']>;
  onlyTypes?: Maybe<Array<ActorType>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type FileContainerEffectiveAccessArgs = {
  searchText?: Maybe<Scalars['String']>;
  onlyTypes?: Maybe<Array<ActorType>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type FileContainerCollaboratorsArgs = {
  searchText?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

/**
 * Download all contents of the specified folder ID.
 * Contents will be zipped with full folder paths relative the selected folder.
 */
export type GetContentsDownloadRequestInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  /** The folder ID to get the contents of. */
  folderId: Scalars['ID'];
  /**
   * Override the relative root folder to download from.
   * The folder must be a parent folder of the selected folder ID or an error will be returned.
   */
  relativeTo?: Maybe<Scalars['ID']>;
  /**
   * DIRECT: retrieves on direct child files from the specified folder.
   * FLAT: retrieves all files beneath the specified folder.
   * 
   * Defaults to FileTreeDepthMode.FLAT.
   */
  depthMode?: Maybe<FileTreeDepthMode>;
  /**
   * false: files will be structured in full folder paths starting from the specified relativeTo folder, or root if not included.
   * true: files will be downloaded in a flat zip folder. relativeTo is ignored.
   * 
   * Warning:
   * If files share names in different folders, a portion of the path may be prefixed to the file name to keep them unique.
   */
  excludePath?: Maybe<Scalars['Boolean']>;
};

export type FilesBatch = {
  __typename: 'FilesBatch';
  files?: Maybe<Array<Maybe<FilesBatchItem>>>;
};

/** Limited subset of file data. */
export type FilesBatchItem = {
  __typename: 'FilesBatchItem';
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/**
 * Request to download a specific set of file IDs.
 * The maximum number of files that may be downloaded this way at one time is 5000.
 */
export type GetBatchFileDownloadRequestInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  /**
   * Download files relative to specified folder root.
   * If not provided, files will be downloaded relative to the file system root folder.
   * If provided, files will be structured in full folder paths starting from the specified folder.
   */
  relativeTo?: Maybe<Scalars['ID']>;
  /**
   * false: files will be structured in full folder paths starting from the specified relativeTo folder, or root if not included.
   * true: files will be downloaded in a flat zip folder. relativeTo is ignored.
   * Warning:
   * If files share names in different folders, a portion of the path may be prefixed to the file name to keep them unique.
   */
  excludePath?: Maybe<Scalars['Boolean']>;
  /**
   * An array of file IDs to download.
   * May be either File or FileSubmission types.
   * Duplicates will be skipped.
   */
  files: Array<Scalars['ID']>;
};

export type IRecycledFileNode = {
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  parent?: Maybe<UFolder>;
  originalPath: Scalars['String'];
  originalPathIds: Array<Scalars['ID']>;
  currentPath: Scalars['String'];
  currentPathIds: Array<Scalars['ID']>;
  fullPath: Scalars['String'];
  depth: Scalars['Int'];
  fileNodeType: FileNodeType;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  deletedById: Scalars['ID'];
  deletedBy: User;
  deletedAt: Scalars['String'];
  hasPermissions?: Maybe<PermissionMap>;
};


export type IRecycledFileNodeCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type IRecycledFileNodeDeletedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};

export type UFolder = Folder | RecycledFolder;

export type RecycledFolder = Node & IRecycledFileNode & {
  __typename: 'RecycledFolder';
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  parent?: Maybe<UFolder>;
  originalPath: Scalars['String'];
  originalPathIds: Array<Scalars['ID']>;
  currentPath: Scalars['String'];
  currentPathIds: Array<Scalars['ID']>;
  fullPath: Scalars['String'];
  depth: Scalars['Int'];
  fileNodeType: FileNodeType;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  deletedById: Scalars['ID'];
  deletedBy: User;
  deletedAt: Scalars['String'];
  hasPermissions?: Maybe<PermissionMap>;
  /** Fetches the recycled child contents of this folder. */
  children: IRecycledFileNodeConnection;
};


export type RecycledFolderCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type RecycledFolderDeletedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type RecycledFolderChildrenArgs = {
  searchText?: Maybe<Scalars['String']>;
  filter?: Maybe<RecycledFileNodeFilterInput>;
  depthMode?: Maybe<FileTreeDepthMode>;
  onlyTypes?: Maybe<Array<FileNodeType>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
};

export type RecycledFileNodeFilterInput = {
  /**
   * Filter results to omit files with an uploadedAt date based on the provided date range filter.
   * This filter ignores Folder and FileContainer types.
   */
  uploadedAt?: Maybe<DateTimeRangeFilter>;
  /**
   * Filter results to omit files not uploaded by the specified users.
   * This filter ignores Folder and FileContainer types.
   */
  uploadedBy?: Maybe<Array<Scalars['ID']>>;
  /** Filters deletedAt date based on the provided date range filter. */
  deletedAt?: Maybe<DateTimeRangeFilter>;
  /** If provided and not empty, filters the results on only the files/folders deleted by the specified users. */
  deletedBy?: Maybe<Array<Scalars['ID']>>;
  /** If provided and not empty, filters the results on only the specified statuses. */
  status?: Maybe<Array<FileNodeStatus>>;
};

/** Look up IRecycledFileNode Listings */
export type IRecycledFileNodeConnection = {
  __typename: 'IRecycledFileNodeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IRecycledFileNodeEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type IRecycledFileNodeEdge = {
  __typename: 'IRecycledFileNodeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<IRecycledFileNode>;
};

export type IRecycledFile = {
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  /** File mime type. */
  contentType?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  parent?: Maybe<UFolder>;
  originalPath: Scalars['String'];
  originalPathIds: Array<Scalars['ID']>;
  currentPath: Scalars['String'];
  currentPathIds: Array<Scalars['ID']>;
  fullPath: Scalars['String'];
  depth: Scalars['Int'];
  fileNodeType: FileNodeType;
  /** File download URL. */
  url: Scalars['String'];
  /** File types without preview support will return null. */
  preview?: Maybe<FilePreview>;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  deletedById: Scalars['ID'];
  deletedBy: User;
  deletedAt: Scalars['String'];
};


export type IRecycledFilePreviewArgs = {
  options?: Maybe<FilePreviewOptions>;
};


export type IRecycledFileCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type IRecycledFileDeletedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};

export type RecycledFileContainer = Node & IRecycledFileNode & {
  __typename: 'RecycledFileContainer';
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  status: FileNodeStatus;
  parentId?: Maybe<Scalars['ID']>;
  parent?: Maybe<UFolder>;
  originalPath: Scalars['String'];
  originalPathIds: Array<Scalars['ID']>;
  currentPath: Scalars['String'];
  currentPathIds: Array<Scalars['ID']>;
  fullPath: Scalars['String'];
  depth: Scalars['Int'];
  fileNodeType: FileNodeType;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  deletedById: Scalars['ID'];
  deletedBy: User;
  deletedAt: Scalars['String'];
  hasPermissions?: Maybe<PermissionMap>;
};


export type RecycledFileContainerCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type RecycledFileContainerDeletedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};

export enum UserNofificationsPriorityFilter {
  Immediate = 'IMMEDIATE',
  Eventual = 'EVENTUAL'
}

export enum UserNotificationsNotifiedFilter {
  Notified = 'NOTIFIED',
  NotNotified = 'NOT_NOTIFIED'
}

export type UserNotificationConnection = {
  __typename: 'UserNotificationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserNotificationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type UserNotificationEdge = {
  __typename: 'UserNotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<UserNotification>;
};

export type UserNotification = Node & {
  __typename: 'UserNotification';
  /** The ID of an object */
  id: Scalars['ID'];
  eventId: Scalars['ID'];
  userId: Scalars['ID'];
  time: Scalars['String'];
  eventType: UserNotificationTopic;
  eventData?: Maybe<NotificationData>;
  title: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  priority?: Maybe<NotificationPriority>;
  notified: Scalars['Boolean'];
  read: Scalars['Boolean'];
};


export type UserNotificationTimeArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};

export type NotificationData = MsDoneNotificationData;

export type MsDoneNotificationData = {
  __typename: 'MSDoneNotificationData';
  milestone: NotificationMilestoneSnapshot;
  submittedByUser: NotificationUserSnapshot;
  job: NotficationJobSnapshot;
};

export type NotificationMilestoneSnapshot = {
  __typename: 'NotificationMilestoneSnapshot';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type NotificationUserSnapshot = {
  __typename: 'NotificationUserSnapshot';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type NotficationJobSnapshot = {
  __typename: 'NotficationJobSnapshot';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum NotificationPriority {
  Immediate = 'IMMEDIATE',
  Eventual = 'EVENTUAL'
}

export enum UserNotificationsReadFilter {
  Unread = 'UNREAD',
  Read = 'READ'
}

export type TeamMembersSortStrategy = {
  key?: Maybe<TeamMembersSortField>;
  order?: Maybe<DirectionEnum>;
};

export enum TeamMembersSortField {
  Id = 'ID',
  CreatedAt = 'CREATED_AT'
}

/** Look up TeamMember Listings */
export type TeamMemberConnection = {
  __typename: 'TeamMemberConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TeamMemberEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type TeamMemberEdge = {
  __typename: 'TeamMemberEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TeamMember>;
};

/** Sync Support direct access to the join relation between users and teams. */
export type TeamMember = Node & {
  __typename: 'TeamMember';
  id: Scalars['ID'];
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
};


/** Sync Support direct access to the join relation between users and teams. */
export type TeamMemberCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};

/** A machine user of the service. */
export type Application = Node & IUser & IActor & {
  __typename: 'Application';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  clientId: Scalars['String'];
  clientSecret?: Maybe<Scalars['String']>;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};


/** A machine user of the service. */
export type ApplicationCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


/** A machine user of the service. */
export type ApplicationUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};

export type ApplicationsSortStrategy = {
  key?: Maybe<ApplicationsSortField>;
  order?: Maybe<DirectionEnum>;
};

export enum ApplicationsSortField {
  Id = 'ID',
  Name = 'NAME',
  ClientId = 'CLIENT_ID',
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Look up Application Listings */
export type ApplicationConnection = {
  __typename: 'ApplicationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ApplicationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type ApplicationEdge = {
  __typename: 'ApplicationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Application>;
};

export type Mutation = {
  __typename: 'Mutation';
  loginApplication?: Maybe<LoginApplicationMutation>;
  login?: Maybe<LoginMutation>;
  register?: Maybe<RegisterMutation>;
  createFolder?: Maybe<CreateFolderMutation>;
  updateFolder?: Maybe<UpdateFolderMutation>;
  moveFolder?: Maybe<MoveFolderMutation>;
  createFileContainer?: Maybe<CreateFileContainerMutation>;
  updateFileContainer?: Maybe<UpdateFileContainerMutation>;
  moveFileContainer?: Maybe<MoveFileContainerMutation>;
  updateFile?: Maybe<UpdateFileMutation>;
  moveFile?: Maybe<MoveFileMutation>;
  updateFileSubmission?: Maybe<UpdateFileSubmissionMutation>;
  convertFileToSubmission?: Maybe<ConvertFileToSubmissionMutation>;
  convertSubmissionToFile?: Maybe<ConvertSubmissionToFileMutation>;
  getFileUploadRequest?: Maybe<GetFileUploadRequestMutation>;
  getFileSubmissionUploadRequest?: Maybe<GetFileSubmissionUploadRequestMutation>;
  getBatchFileUploadsRequest?: Maybe<GetBatchFileUploadsMutation>;
  getBatchFileSubmissionUploadsRequest?: Maybe<GetBatchFileSubmissionUploadsMutation>;
  updateUploadProgress?: Maybe<UpdateUploadProgressMutation>;
  updateBatchUploadProgress?: Maybe<UpdateBatchUploadProgressMutation>;
  /**
   * retryUpload(input: RetryUploadInput!): RetryUploadMutation
   * retryBatchUpload(input: RetryBatchUploadInput!): RetryBatchUploadMutation
   */
  getContentsDownloadRequest?: Maybe<GetContentsDownloadRequestMutation>;
  getBatchFileDownloadRequest?: Maybe<GetBatchFileDownloadRequestMutation>;
  lockFileNode?: Maybe<LockFileNodeMutation>;
  unlockFileNode?: Maybe<UnlockFileNodeMutation>;
  lockFileNodeBatch?: Maybe<LockFileNodeBatchMutation>;
  unlockFileNodeBatch?: Maybe<UnlockFileNodeBatchMutation>;
  fileNodeAddMember?: Maybe<FileNodeAddMemberMutation>;
  fileNodeBlockMember?: Maybe<FileNodeBlockMemberMutation>;
  fileNodeUpdateMember?: Maybe<FileNodeUpdateMemberMutation>;
  fileNodeRemoveMember?: Maybe<FileNodeRemoveMemberMutation>;
  fileNodeAddMemberBatch?: Maybe<FileNodeAddMemberBatchMutation>;
  fileNodeBlockMemberBatch?: Maybe<FileNodeBlockMemberBatchMutation>;
  fileNodeUpdateMemberBatch?: Maybe<FileNodeUpdateMemberBatchMutation>;
  fileNodeRemoveMemberBatch?: Maybe<FileNodeRemoveMemberBatchMutation>;
  updateRecycledFileNode?: Maybe<UpdateRecycledFileNodeMutation>;
  recycleFileNode?: Maybe<RecycleFileNodeMutation>;
  recycleFileNodeBatch?: Maybe<RecycleFileNodeBatchMutation>;
  recoverFileNode?: Maybe<RecoverFileNodeMutation>;
  recoverFileNodeBatch?: Maybe<RecoverFileNodeBatchMutation>;
  deleteFileNode?: Maybe<DeleteFileNodeMutation>;
  deleteFileNodeBatch?: Maybe<DeleteFileNodeBatchMutation>;
  resetFileContainer?: Maybe<ResetFileContainerMutation>;
  cancelFileContainer?: Maybe<CancelFileContainerMutation>;
  submitFileContainer?: Maybe<SubmitFileContainerMutation>;
  rejectFileContainer?: Maybe<RejectFileContainerMutation>;
  approveFileContainer?: Maybe<ApproveFileContainerMutation>;
  updateFileContainerStatusBatch?: Maybe<UpdateFileContainerStatusBatchMutation>;
  archiveJob?: Maybe<ArchiveJobMutation>;
  unarchiveJob?: Maybe<UnarchiveJobMutation>;
  archiveJobBatch?: Maybe<ArchiveJobBatchMutation>;
  unarchiveJobBatch?: Maybe<UnarchiveJobBatchMutation>;
  importJob?: Maybe<ImportJobMutation>;
  importJobData?: Maybe<ImportJobDataMutation>;
  createJob?: Maybe<CreateJobMutation>;
  updateJob?: Maybe<UpdateJobMutation>;
  deleteJob?: Maybe<DeleteJobMutation>;
  deleteJobBatch?: Maybe<DeleteJobBatchMutation>;
  resetJob?: Maybe<ResetJobMutation>;
  startJob?: Maybe<StartJobMutation>;
  cancelJob?: Maybe<CancelJobMutation>;
  completeJob?: Maybe<CompleteJobMutation>;
  updateJobStatusBatch?: Maybe<UpdateJobStatusBatchMutation>;
  createJobTemplate?: Maybe<CreateJobTemplateMutation>;
  updateJobTemplate?: Maybe<UpdateJobTemplateMutation>;
  deleteJobTemplate?: Maybe<DeleteJobTemplateMutation>;
  deleteJobTemplateBatch?: Maybe<DeleteJobTemplateBatchMutation>;
  /** Lock Management */
  acquireLockForTemplate?: Maybe<AcquireLockForTemplateMutation>;
  releaseLockForTemplate?: Maybe<ReleaseLockForTemplateMutation>;
  forceReleaseLockForTemplate?: Maybe<ForceReleaseLockForTemplateMutation>;
  createMilestone?: Maybe<CreateMilestoneMutation>;
  updateMilestone?: Maybe<UpdateMilestoneMutation>;
  deleteMilestone?: Maybe<DeleteMilestoneMutation>;
  deleteMilestoneBatch?: Maybe<DeleteMilestoneBatchMutation>;
  resetMilestone?: Maybe<ResetMilestoneMutation>;
  cancelMilestone?: Maybe<CancelMilestoneMutation>;
  submitMilestone?: Maybe<SubmitMilestoneMutation>;
  startReviewMilestone?: Maybe<StartReviewMilestoneMutation>;
  approveMilestone?: Maybe<ApproveMilestoneMutation>;
  updateMilestoneStatusBatch?: Maybe<UpdateMilestoneStatusBatchMutation>;
  setNotificationsNotified: SetNotificationsNotifiedMutation;
  setNotificationRead: SetNotificationReadMutation;
  createOrganization?: Maybe<CreateOrganizationMutation>;
  updateOrganization?: Maybe<UpdateOrganizationMutation>;
  deleteOrganization?: Maybe<DeleteOrganizationMutation>;
  /** Create a new user account and add them to the current organization. */
  createOrganizationUser?: Maybe<CreateOrganizationUserMutation>;
  /**
   * Add an existing user to an organization.
   * May only be used by service admins.
   */
  addUserToOrganization?: Maybe<AddUserToOrganizationMutation>;
  /** Update the role a current member of an organization. */
  changeUserRoleInOrganization?: Maybe<ChangeUserRoleInOrganizationMutation>;
  /** Remove a member from an organization. */
  removeUserFromOrganization?: Maybe<RemoveUserFromOrganizationMutation>;
  /** Create a new user account and add them to the current organization. */
  createOrganizationUserBatch?: Maybe<CreateOrganizationUserBatchMutation>;
  /** Update the roles of multiple members of an organization. */
  changeUserRoleInOrganizationBatch?: Maybe<ChangeUserRoleInOrganizationBatchMutation>;
  /** Remove multiple members from an organization. */
  removeUserFromOrganizationBatch?: Maybe<RemoveUserFromOrganizationBatchMutation>;
  archiveProject?: Maybe<ArchiveProjectMutation>;
  unarchiveProject?: Maybe<UnarchiveProjectMutation>;
  archiveProjectBatch?: Maybe<ArchiveProjectBatchMutation>;
  unarchiveProjectBatch?: Maybe<UnarchiveProjectBatchMutation>;
  createProject?: Maybe<CreateProjectMutation>;
  updateProject?: Maybe<UpdateProjectMutation>;
  deleteProject?: Maybe<DeleteProjectMutation>;
  deleteProjectBatch?: Maybe<DeleteProjectBatchMutation>;
  resetProject?: Maybe<ResetProjectMutation>;
  startProject?: Maybe<StartProjectMutation>;
  cancelProject?: Maybe<CancelProjectMutation>;
  completeProject?: Maybe<CompleteProjectMutation>;
  updateProjectStatusBatch?: Maybe<UpdateProjectStatusBatchMutation>;
  createProjectTemplate?: Maybe<CreateProjectTemplateMutation>;
  updateProjectTemplate?: Maybe<UpdateProjectTemplateMutation>;
  deleteProjectTemplate?: Maybe<DeleteProjectTemplateMutation>;
  deleteProjectTemplateBatch?: Maybe<DeleteProjectTemplateBatchMutation>;
  accessedTenant?: Maybe<AccessedTenantMutation>;
  accessedProject?: Maybe<AccessedProjectMutation>;
  accessedJob?: Maybe<AccessedJobMutation>;
  accessedFolder?: Maybe<AccessedFolderMutation>;
  accessedFile?: Maybe<AccessedFileMutation>;
  createTemplateRepository?: Maybe<CreateTemplateRepositoryMutation>;
  updateTemplateRepository?: Maybe<UpdateTemplateRepositoryMutation>;
  deleteTemplateRepository?: Maybe<DeleteTemplateRepositoryMutation>;
  deleteTemplateRepositoryBatch?: Maybe<DeleteTemplateRepositoryBatchMutation>;
  createTemplateCommit?: Maybe<CreateTemplateCommitMutation>;
  updateTemplateCommit?: Maybe<UpdateTemplateCommitMutation>;
  resetToTemplateCommit?: Maybe<ResetToTemplateCommitMutation>;
  deleteTemplateCommit?: Maybe<DeleteTemplateCommitMutation>;
  deleteTemplateCommitBatch?: Maybe<DeleteTemplateCommitBatchMutation>;
  createTeam?: Maybe<CreateTeamMutation>;
  updateTeam?: Maybe<UpdateTeamMutation>;
  deleteTeam?: Maybe<DeleteTeamMutation>;
  addMemberToTeam?: Maybe<AddMemberToTeamMutation>;
  removeMemberFromTeam?: Maybe<RemoveMemberFromTeamMutation>;
  deleteTeamBatch?: Maybe<DeleteTeamBatchMutation>;
  addMemberToTeamBatch?: Maybe<AddMemberToTeamBatchMutation>;
  removeMemberFromTeamBatch?: Maybe<RemoveMemberFromTeamBatchMutation>;
  createApplication?: Maybe<CreateApplicationMutation>;
  updateApplication?: Maybe<UpdateApplicationMutation>;
  deleteApplication?: Maybe<DeleteApplicationMutation>;
  createUser?: Maybe<CreateUserMutation>;
  updateUser?: Maybe<UpdateUserMutation>;
  deleteUser?: Maybe<DeleteUserMutation>;
  setUsersLastEventualNotification?: Maybe<SetUsersLastEventualNotificationMutation>;
};


export type MutationLoginApplicationArgs = {
  input: LoginApplicationInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationCreateFolderArgs = {
  input: CreateFolderInput;
};


export type MutationUpdateFolderArgs = {
  input: UpdateFolderInput;
};


export type MutationMoveFolderArgs = {
  input: MoveFolderInput;
};


export type MutationCreateFileContainerArgs = {
  input: CreateFileContainerInput;
};


export type MutationUpdateFileContainerArgs = {
  input: UpdateFileContainerInput;
};


export type MutationMoveFileContainerArgs = {
  input: MoveFileContainerInput;
};


export type MutationUpdateFileArgs = {
  input: UpdateFileInput;
};


export type MutationMoveFileArgs = {
  input: MoveFileInput;
};


export type MutationUpdateFileSubmissionArgs = {
  input: UpdateFileSubmissionInput;
};


export type MutationConvertFileToSubmissionArgs = {
  input: ConvertFileToSubmissionInput;
};


export type MutationConvertSubmissionToFileArgs = {
  input: ConvertSubmissionToFileInput;
};


export type MutationGetFileUploadRequestArgs = {
  input: GetFileUploadRequestInput;
};


export type MutationGetFileSubmissionUploadRequestArgs = {
  input: GetFileSubmissionUploadRequestInput;
};


export type MutationGetBatchFileUploadsRequestArgs = {
  input: GetBatchFileUploadsInput;
};


export type MutationGetBatchFileSubmissionUploadsRequestArgs = {
  input: GetBatchFileSubmissionUploadsInput;
};


export type MutationUpdateUploadProgressArgs = {
  input: UpdateUploadProgressInput;
};


export type MutationUpdateBatchUploadProgressArgs = {
  input: UpdateBatchUploadProgressInput;
};


export type MutationGetContentsDownloadRequestArgs = {
  input: GetContentsDownloadRequestInput;
};


export type MutationGetBatchFileDownloadRequestArgs = {
  input: GetBatchFileDownloadRequestInput;
};


export type MutationLockFileNodeArgs = {
  input: LockFileNodeInput;
};


export type MutationUnlockFileNodeArgs = {
  input: UnlockFileNodeInput;
};


export type MutationLockFileNodeBatchArgs = {
  input: LockFileNodeBatchInput;
};


export type MutationUnlockFileNodeBatchArgs = {
  input: UnlockFileNodeBatchInput;
};


export type MutationFileNodeAddMemberArgs = {
  input: FileNodeAddMemberInput;
};


export type MutationFileNodeBlockMemberArgs = {
  input: FileNodeBlockMemberInput;
};


export type MutationFileNodeUpdateMemberArgs = {
  input: FileNodeUpdateMemberInput;
};


export type MutationFileNodeRemoveMemberArgs = {
  input: FileNodeRemoveMemberInput;
};


export type MutationFileNodeAddMemberBatchArgs = {
  input: FileNodeAddMemberBatchInput;
};


export type MutationFileNodeBlockMemberBatchArgs = {
  input: FileNodeBlockMemberBatchInput;
};


export type MutationFileNodeUpdateMemberBatchArgs = {
  input: FileNodeUpdateMemberBatchInput;
};


export type MutationFileNodeRemoveMemberBatchArgs = {
  input: FileNodeRemoveMemberBatchInput;
};


export type MutationUpdateRecycledFileNodeArgs = {
  input: UpdateRecycledFileNodeInput;
};


export type MutationRecycleFileNodeArgs = {
  input: RecycleFileNodeInput;
};


export type MutationRecycleFileNodeBatchArgs = {
  input: RecycleFileNodeBatchInput;
};


export type MutationRecoverFileNodeArgs = {
  input: RecoverFileNodeInput;
};


export type MutationRecoverFileNodeBatchArgs = {
  input: RecoverFileNodeBatchInput;
};


export type MutationDeleteFileNodeArgs = {
  input: DeleteFileNodeInput;
};


export type MutationDeleteFileNodeBatchArgs = {
  input: DeleteFileNodeBatchInput;
};


export type MutationResetFileContainerArgs = {
  input: ResetFileContainerInput;
};


export type MutationCancelFileContainerArgs = {
  input: CancelFileContainerInput;
};


export type MutationSubmitFileContainerArgs = {
  input: SubmitFileContainerInput;
};


export type MutationRejectFileContainerArgs = {
  input: RejectFileContainerInput;
};


export type MutationApproveFileContainerArgs = {
  input: ApproveFileContainerInput;
};


export type MutationUpdateFileContainerStatusBatchArgs = {
  input: UpdateFileContainerStatusBatchInput;
};


export type MutationArchiveJobArgs = {
  input: ArchiveJobInput;
};


export type MutationUnarchiveJobArgs = {
  input: UnarchiveJobInput;
};


export type MutationArchiveJobBatchArgs = {
  input: ArchiveJobBatchInput;
};


export type MutationUnarchiveJobBatchArgs = {
  input: UnarchiveJobBatchInput;
};


export type MutationImportJobArgs = {
  input: ImportJobInput;
};


export type MutationImportJobDataArgs = {
  input: ImportJobDataInput;
};


export type MutationCreateJobArgs = {
  input: CreateJobInput;
};


export type MutationUpdateJobArgs = {
  input: UpdateJobInput;
};


export type MutationDeleteJobArgs = {
  input: DeleteJobInput;
};


export type MutationDeleteJobBatchArgs = {
  input: DeleteJobBatchInput;
};


export type MutationResetJobArgs = {
  input: ResetJobInput;
};


export type MutationStartJobArgs = {
  input: StartJobInput;
};


export type MutationCancelJobArgs = {
  input: CancelJobInput;
};


export type MutationCompleteJobArgs = {
  input: CompleteJobInput;
};


export type MutationUpdateJobStatusBatchArgs = {
  input: UpdateJobStatusBatchInput;
};


export type MutationCreateJobTemplateArgs = {
  input: CreateJobTemplateInput;
};


export type MutationUpdateJobTemplateArgs = {
  input: UpdateJobTemplateInput;
};


export type MutationDeleteJobTemplateArgs = {
  input: DeleteJobTemplateInput;
};


export type MutationDeleteJobTemplateBatchArgs = {
  input: DeleteJobTemplateBatchInput;
};


export type MutationAcquireLockForTemplateArgs = {
  input: AcquireLockForTemplateInput;
};


export type MutationReleaseLockForTemplateArgs = {
  input: ReleaseLockForTemplateInput;
};


export type MutationForceReleaseLockForTemplateArgs = {
  input: ForceReleaseLockForTemplateInput;
};


export type MutationCreateMilestoneArgs = {
  input: CreateMilestoneInput;
};


export type MutationUpdateMilestoneArgs = {
  input: UpdateMilestoneInput;
};


export type MutationDeleteMilestoneArgs = {
  input: DeleteMilestoneInput;
};


export type MutationDeleteMilestoneBatchArgs = {
  input: DeleteMilestoneBatchInput;
};


export type MutationResetMilestoneArgs = {
  input: ResetMilestoneInput;
};


export type MutationCancelMilestoneArgs = {
  input: CancelMilestoneInput;
};


export type MutationSubmitMilestoneArgs = {
  input: SubmitMilestoneInput;
};


export type MutationStartReviewMilestoneArgs = {
  input: StartReviewMilestoneInput;
};


export type MutationApproveMilestoneArgs = {
  input: ApproveMilestoneInput;
};


export type MutationUpdateMilestoneStatusBatchArgs = {
  input: UpdateMilestoneStatusBatchInput;
};


export type MutationSetNotificationsNotifiedArgs = {
  input: SetNotificationsNotifiedInput;
};


export type MutationSetNotificationReadArgs = {
  input: SetNotificationReadInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput;
};


export type MutationCreateOrganizationUserArgs = {
  input: CreateOrganizationUserInput;
};


export type MutationAddUserToOrganizationArgs = {
  input: AddUserToOrganizationInput;
};


export type MutationChangeUserRoleInOrganizationArgs = {
  input: ChangeUserRoleInOrganizationInput;
};


export type MutationRemoveUserFromOrganizationArgs = {
  input: RemoveUserFromOrganizationInput;
};


export type MutationCreateOrganizationUserBatchArgs = {
  input: CreateOrganizationUserBatchInput;
};


export type MutationChangeUserRoleInOrganizationBatchArgs = {
  input: ChangeUserRoleInOrganizationBatchInput;
};


export type MutationRemoveUserFromOrganizationBatchArgs = {
  input: RemoveUserFromOrganizationBatchInput;
};


export type MutationArchiveProjectArgs = {
  input: ArchiveProjectInput;
};


export type MutationUnarchiveProjectArgs = {
  input: UnarchiveProjectInput;
};


export type MutationArchiveProjectBatchArgs = {
  input: ArchiveProjectBatchInput;
};


export type MutationUnarchiveProjectBatchArgs = {
  input: UnarchiveProjectBatchInput;
};


export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};


export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};


export type MutationDeleteProjectArgs = {
  input: DeleteProjectInput;
};


export type MutationDeleteProjectBatchArgs = {
  input: DeleteProjectBatchInput;
};


export type MutationResetProjectArgs = {
  input: ResetProjectInput;
};


export type MutationStartProjectArgs = {
  input: StartProjectInput;
};


export type MutationCancelProjectArgs = {
  input: CancelProjectInput;
};


export type MutationCompleteProjectArgs = {
  input: CompleteProjectInput;
};


export type MutationUpdateProjectStatusBatchArgs = {
  input: UpdateProjectStatusBatchInput;
};


export type MutationCreateProjectTemplateArgs = {
  input: CreateProjectTemplateInput;
};


export type MutationUpdateProjectTemplateArgs = {
  input: UpdateProjectTemplateInput;
};


export type MutationDeleteProjectTemplateArgs = {
  input: DeleteProjectTemplateInput;
};


export type MutationDeleteProjectTemplateBatchArgs = {
  input: DeleteProjectTemplateBatchInput;
};


export type MutationAccessedTenantArgs = {
  id: Scalars['ID'];
};


export type MutationAccessedProjectArgs = {
  id: Scalars['ID'];
};


export type MutationAccessedJobArgs = {
  id: Scalars['ID'];
};


export type MutationAccessedFolderArgs = {
  id: Scalars['ID'];
};


export type MutationAccessedFileArgs = {
  id: Scalars['ID'];
};


export type MutationCreateTemplateRepositoryArgs = {
  input: CreateTemplateRepositoryInput;
};


export type MutationUpdateTemplateRepositoryArgs = {
  input: UpdateTemplateRepositoryInput;
};


export type MutationDeleteTemplateRepositoryArgs = {
  input: DeleteTemplateRepositoryInput;
};


export type MutationDeleteTemplateRepositoryBatchArgs = {
  input: DeleteTemplateRepositoryBatchInput;
};


export type MutationCreateTemplateCommitArgs = {
  input: CreateTemplateCommitInput;
};


export type MutationUpdateTemplateCommitArgs = {
  input: UpdateTemplateCommitInput;
};


export type MutationResetToTemplateCommitArgs = {
  input: ResetToTemplateCommitInput;
};


export type MutationDeleteTemplateCommitArgs = {
  input: DeleteTemplateCommitInput;
};


export type MutationDeleteTemplateCommitBatchArgs = {
  input: DeleteTemplateCommitBatchInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput;
};


export type MutationDeleteTeamArgs = {
  input: DeleteTeamInput;
};


export type MutationAddMemberToTeamArgs = {
  input: AddMemberToTeamInput;
};


export type MutationRemoveMemberFromTeamArgs = {
  input: RemoveMemberFromTeamInput;
};


export type MutationDeleteTeamBatchArgs = {
  input: DeleteTeamBatchInput;
};


export type MutationAddMemberToTeamBatchArgs = {
  input: AddMemberToTeamBatchInput;
};


export type MutationRemoveMemberFromTeamBatchArgs = {
  input: RemoveMemberFromTeamBatchInput;
};


export type MutationCreateApplicationArgs = {
  input: CreateApplicationInput;
};


export type MutationUpdateApplicationArgs = {
  input: UpdateApplicationInput;
};


export type MutationDeleteApplicationArgs = {
  input: DeleteApplicationInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationSetUsersLastEventualNotificationArgs = {
  input: SetUsersLastEventualNotificationInput;
};

export type LoginApplicationInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type LoginApplicationMutation = MutationResponse & {
  __typename: 'LoginApplicationMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  application?: Maybe<Application>;
  token?: Maybe<Scalars['String']>;
};

export type MutationResponse = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type ValidationError = {
  __typename: 'ValidationError';
  /** If the field is null, the message applies to the overall operation. */
  field?: Maybe<Scalars['String']>;
  /** Message to display to user regarding the failed input value. */
  message: Scalars['String'];
};

export type LoginInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginMutation = MutationResponse & {
  __typename: 'LoginMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  user?: Maybe<User>;
  token?: Maybe<Scalars['String']>;
};

export type RegisterInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  login?: Maybe<Scalars['Boolean']>;
};

export type RegisterMutation = MutationResponse & {
  __typename: 'RegisterMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  user?: Maybe<User>;
  token?: Maybe<Scalars['String']>;
};

export type CreateFolderInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  parentId: Scalars['ID'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  allowFiles?: Maybe<Scalars['Boolean']>;
  /**
   * The maximum number of file that may be uploaded to the folder.
   * If allowFiles is false, this value is ignored.
   */
  maxAllowed?: Maybe<Scalars['Int']>;
  /**
   * Specify a maximum allowed file size for any file uploaded directly to the folder.
   * Does not affect child folders or file containers.
   * null selects the default of the maximum allowed by the Service.
   */
  maxFileSize?: Maybe<Scalars['BigInt']>;
};

export type CreateFolderMutation = MutationResponse & {
  __typename: 'CreateFolderMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  folder?: Maybe<Folder>;
};

export type UpdateFolderInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  allowFiles?: Maybe<Scalars['Boolean']>;
  /**
   * The maximum number of file that may be uploaded to the folder.
   * If allowFiles is false, this value is ignored.
   */
  maxAllowed?: Maybe<Scalars['Int']>;
  /**
   * Specify a maximum allowed file size for any file uploaded directly to the folder.
   * Does not affect child folders or file containers.
   * null resets to the default.
   */
  maxFileSize?: Maybe<Scalars['BigInt']>;
};

export type UpdateFolderMutation = MutationResponse & {
  __typename: 'UpdateFolderMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  folder?: Maybe<Folder>;
};

export type MoveFolderInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  /** The id of the file node to update. */
  id: Scalars['ID'];
  /**
   * Provide the current parentId at the time of the request.
   * This is a check against simultaneous edit race conditions.
   */
  fromParentId: Scalars['ID'];
  /** The parentId of the folder to move the node to. */
  toParentId: Scalars['ID'];
};

export type MoveFolderMutation = MutationResponse & {
  __typename: 'MoveFolderMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  folder?: Maybe<Folder>;
};

export type CreateFileContainerInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  parentId: Scalars['ID'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  renameFile?: Maybe<Scalars['Boolean']>;
  minSuggestion?: Maybe<Scalars['Int']>;
  maxSuggestion?: Maybe<Scalars['Int']>;
  minRequired?: Maybe<Scalars['Int']>;
  maxAllowed?: Maybe<Scalars['Int']>;
  /**
   * Specify a maximum allowed file size for any file uploaded directly to the folder.
   * Does not affect child folders or file containers.
   * null selects the default.
   */
  maxFileSize?: Maybe<Scalars['BigInt']>;
  /** White list of allowed file extensions that may be uploaded. */
  whitelistExtensions?: Maybe<Array<Scalars['String']>>;
  /** Selected standard file type to define file constraints. */
  inheritedFileType?: Maybe<Scalars['ID']>;
};

export type CreateFileContainerMutation = MutationResponse & {
  __typename: 'CreateFileContainerMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileContainer?: Maybe<FileContainer>;
};

export type UpdateFileContainerInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  renameFile?: Maybe<Scalars['Boolean']>;
  minSuggestion?: Maybe<Scalars['Int']>;
  maxSuggestion?: Maybe<Scalars['Int']>;
  minRequired?: Maybe<Scalars['Int']>;
  maxAllowed?: Maybe<Scalars['Int']>;
  /** null resets to the default. */
  maxFileSize?: Maybe<Scalars['BigInt']>;
  whitelistExtensions?: Maybe<Array<Scalars['String']>>;
  inheritedFileType?: Maybe<Scalars['ID']>;
};

export type UpdateFileContainerMutation = MutationResponse & {
  __typename: 'UpdateFileContainerMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileContainer?: Maybe<FileContainer>;
};

export type MoveFileContainerInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  /** The id of the file node to update. */
  id: Scalars['ID'];
  /**
   * Provide the current parentId at the time of the request.
   * This is a check against simultaneous edit race conditions.
   */
  fromParentId: Scalars['ID'];
  /** The parentId of the folder to move the node to. */
  toParentId: Scalars['ID'];
};

export type MoveFileContainerMutation = MutationResponse & {
  __typename: 'MoveFileContainerMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileContainer?: Maybe<FileContainer>;
};

export type UpdateFileInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type UpdateFileMutation = MutationResponse & {
  __typename: 'UpdateFileMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  file?: Maybe<File>;
};

export type File = Node & IFileNode & IFile & ISharableFileNode & {
  __typename: 'File';
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  parent?: Maybe<Folder>;
  path: Scalars['String'];
  fullPath: Scalars['String'];
  pathIds: Array<Scalars['ID']>;
  depth: Scalars['Int'];
  fileNodeType: FileNodeType;
  locked: FileNodeLock;
  lockedBy?: Maybe<IFileNode>;
  uploading: Scalars['Boolean'];
  uploadProgress?: Maybe<Scalars['Float']>;
  url: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  exif?: Maybe<Exif>;
  preview?: Maybe<FilePreview>;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  hasPermissions?: Maybe<PermissionMap>;
  assignableActors?: Maybe<IActorConnection>;
  assignableRoles?: Maybe<FileRoleConnection>;
  members?: Maybe<FileNodeMembershipConnection>;
  blocked?: Maybe<FileNodeMembershipConnection>;
  effectiveAccess?: Maybe<IActorConnection>;
  currentOrganizationMembership?: Maybe<FileNodeMembership>;
  collaborators?: Maybe<FileNodeOrganizationCollaboratorConnection>;
};


export type FilePreviewArgs = {
  options?: Maybe<FilePreviewOptions>;
};


export type FileCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type FileUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type FileAssignableActorsArgs = {
  searchText?: Maybe<Scalars['String']>;
  onlyTypes?: Maybe<Array<ActorType>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type FileAssignableRolesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type FileMembersArgs = {
  searchText?: Maybe<Scalars['String']>;
  onlyTypes?: Maybe<Array<ActorType>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type FileBlockedArgs = {
  searchText?: Maybe<Scalars['String']>;
  onlyTypes?: Maybe<Array<ActorType>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type FileEffectiveAccessArgs = {
  searchText?: Maybe<Scalars['String']>;
  onlyTypes?: Maybe<Array<ActorType>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type FileCollaboratorsArgs = {
  searchText?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type MoveFileInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  /** The id of the file node to update. */
  id: Scalars['ID'];
  /**
   * Provide the current parentId at the time of the request.
   * This is a check against simultaneous edit race conditions.
   */
  fromParentId: Scalars['ID'];
  /** The parentId of the folder to move the node to. */
  toParentId: Scalars['ID'];
};

export type MoveFileMutation = MutationResponse & {
  __typename: 'MoveFileMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  file?: Maybe<File>;
};

/**
 * Update a FileSubmission.
 * allowCustomName must be true or changed to true in order to edit the name.
 * Attempting to update name while allowCustomName is false will result in an error.
 */
export type UpdateFileSubmissionInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  allowCustomName?: Maybe<Scalars['Boolean']>;
};

export type UpdateFileSubmissionMutation = MutationResponse & {
  __typename: 'UpdateFileSubmissionMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileSubmission?: Maybe<FileSubmission>;
};

export type FileSubmission = Node & IFileNode & IFile & {
  __typename: 'FileSubmission';
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  /** The name of the file as it was uploaded. */
  originalName: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
  parent?: Maybe<Folder>;
  fileContainerId: Scalars['ID'];
  fileContainer: FileContainer;
  path: Scalars['String'];
  fullPath: Scalars['String'];
  pathIds: Array<Scalars['ID']>;
  depth: Scalars['Int'];
  fileNodeType: FileNodeType;
  locked: FileNodeLock;
  lockedBy?: Maybe<IFileNode>;
  status: FileNodeStatus;
  uploading: Scalars['Boolean'];
  uploadProgress?: Maybe<Scalars['Float']>;
  url: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  exif?: Maybe<Exif>;
  preview?: Maybe<FilePreview>;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  /**
   * When false, name will match fileContainer.name + file extension.
   * When true, name will match originalName or a user provided override.
   */
  allowCustomName?: Maybe<Scalars['Boolean']>;
  /** Note that the permissions for FileSubmission are taken from its FileContainer. */
  hasPermissions?: Maybe<PermissionMap>;
};


export type FileSubmissionPreviewArgs = {
  options?: Maybe<FilePreviewOptions>;
};


export type FileSubmissionCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type FileSubmissionUpdatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};

export type ConvertFileToSubmissionInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  /** The id of the File to convert to a FileSubmission. */
  id: Scalars['ID'];
  /** The id of the FileContainer to associate the FileSubmission with. */
  fileContainerId: Scalars['ID'];
  /** Override the default renaming behavior of the target FileContainer */
  allowCustomName?: Maybe<Scalars['Boolean']>;
};

export type ConvertFileToSubmissionMutation = MutationResponse & {
  __typename: 'ConvertFileToSubmissionMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileSubmission?: Maybe<FileSubmission>;
};

/**
 * Convert an existing FileSubmission to a File.
 * Optionally, move the updated File to a different parent Folder at the same time.
 * If the file is being moved, fromParentId and toParentId must both be provided.
 */
export type ConvertSubmissionToFileInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  /** The id of the FileSubmission to convert to a File. */
  id: Scalars['ID'];
  /**
   * Provide the current parentId at the time of the request.
   * This is a check against simultaneous edit race conditions.
   */
  fromParentId?: Maybe<Scalars['ID']>;
  /** The parentId of the folder to move the node to. */
  toParentId?: Maybe<Scalars['ID']>;
};

export type ConvertSubmissionToFileMutation = MutationResponse & {
  __typename: 'ConvertSubmissionToFileMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  file?: Maybe<File>;
};

/** Ask the server to generate a request to upload the file directly to the object store service. */
export type GetFileUploadRequestInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  fileName: Scalars['String'];
  parentId: Scalars['ID'];
  size?: Maybe<Scalars['Int']>;
  md5?: Maybe<Scalars['String']>;
};

export type GetFileUploadRequestMutation = MutationResponse & IFileUploadRequest & {
  __typename: 'GetFileUploadRequestMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  signedRequestUrl?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<FileUploadRequestField>>;
  fileId?: Maybe<Scalars['ID']>;
  file?: Maybe<File>;
};

export type IFileUploadRequest = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  /** Signed HTTP POST URL for form upload. */
  signedRequestUrl?: Maybe<Scalars['String']>;
  /** List of all key-value pairs required to be added to the upload form. */
  fields?: Maybe<Array<FileUploadRequestField>>;
  /**
   * The new file ID to be uploaded.
   * Provides a reliable way to retrieve the ID of the new object in case the lookup of the file fails.
   */
  fileId?: Maybe<Scalars['ID']>;
  /**
   * The file to be uploaded.
   * 
   * Note: The download url is available immediately but will not work until after the upload is successful.
   * Use retry with exponential-backoff to retrieve the file or thumbnails.
   */
  file?: Maybe<IFile>;
};

/** Key value pair to add to the upload form when submitting a post request. */
export type FileUploadRequestField = {
  __typename: 'FileUploadRequestField';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Ask the server to generate a request to upload the file directly to the object store service. */
export type GetFileSubmissionUploadRequestInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  fileName: Scalars['String'];
  fileContainerId: Scalars['ID'];
  size?: Maybe<Scalars['Int']>;
  md5?: Maybe<Scalars['String']>;
  /** Override the default renaming behavior of the target FileContainer */
  allowCustomName?: Maybe<Scalars['Boolean']>;
};

export type GetFileSubmissionUploadRequestMutation = MutationResponse & IFileUploadRequest & {
  __typename: 'GetFileSubmissionUploadRequestMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  signedRequestUrl?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<FileUploadRequestField>>;
  fileId?: Maybe<Scalars['ID']>;
  file?: Maybe<FileSubmission>;
};

export type GetBatchFileUploadsInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  requests: Array<GetFileUploadRequestInput>;
};

export type GetBatchFileUploadsMutation = MutationResponse & {
  __typename: 'GetBatchFileUploadsMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  requests?: Maybe<Array<FileUploadRequest>>;
};

export type FileUploadRequest = MutationResponse & IFileUploadRequest & {
  __typename: 'FileUploadRequest';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  signedRequestUrl?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<FileUploadRequestField>>;
  fileId?: Maybe<Scalars['ID']>;
  file?: Maybe<IFile>;
};

export type GetBatchFileSubmissionUploadsInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  requests: Array<GetFileSubmissionUploadRequestInput>;
};

export type GetBatchFileSubmissionUploadsMutation = MutationResponse & {
  __typename: 'GetBatchFileSubmissionUploadsMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  requests?: Maybe<Array<FileUploadRequest>>;
};

export type UpdateUploadProgressInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  status: UploadProgress;
  progress?: Maybe<Scalars['Float']>;
};

export enum UploadProgress {
  InProgress = 'IN_PROGRESS',
  Failed = 'FAILED',
  Cancelled = 'CANCELLED'
}

export type UpdateUploadProgressMutation = MutationResponse & {
  __typename: 'UpdateUploadProgressMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type UpdateBatchUploadProgressInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  requests: Array<UpdateUploadProgressInput>;
};

export type UpdateBatchUploadProgressMutation = MutationResponse & {
  __typename: 'UpdateBatchUploadProgressMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  requests?: Maybe<Array<UpdateUploadProgressMutation>>;
};

/** The download will be available at the returned url. */
export type GetContentsDownloadRequestMutation = {
  __typename: 'GetContentsDownloadRequestMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  url?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
};

/** The download will be available at the returned url. */
export type GetBatchFileDownloadRequestMutation = {
  __typename: 'GetBatchFileDownloadRequestMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  url?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
};

export type LockFileNodeInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
};

export type LockFileNodeMutation = MutationResponse & {
  __typename: 'LockFileNodeMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileNode?: Maybe<IFileNode>;
};

export type UnlockFileNodeInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
};

export type UnlockFileNodeMutation = MutationResponse & {
  __typename: 'UnlockFileNodeMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileNode?: Maybe<IFileNode>;
};

export type LockFileNodeBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<Scalars['ID']>;
};

export type LockFileNodeBatchMutation = MutationResponse & {
  __typename: 'LockFileNodeBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<LockFileNodeBatchItemMutation>>>;
  fileNodes?: Maybe<Array<Maybe<IFileNode>>>;
};

export type LockFileNodeBatchItemMutation = MutationResponse & {
  __typename: 'LockFileNodeBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileNodeId?: Maybe<Scalars['ID']>;
};

export type UnlockFileNodeBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<Scalars['ID']>;
};

export type UnlockFileNodeBatchMutation = MutationResponse & {
  __typename: 'UnlockFileNodeBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<UnlockFileNodeBatchItemMutation>>>;
  fileNodes?: Maybe<Array<Maybe<IFileNode>>>;
};

export type UnlockFileNodeBatchItemMutation = MutationResponse & {
  __typename: 'UnlockFileNodeBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileNodeId?: Maybe<Scalars['ID']>;
};

/** Add a new member to a FileNode. */
export type FileNodeAddMemberInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  /** The FileNode to modify access to. */
  fileNodeId: Scalars['ID'];
  /** The actor to grant access to. */
  targetActorId: Scalars['ID'];
  /** The role to grant. */
  fileRoleId: Scalars['ID'];
};

export type FileNodeAddMemberMutation = MutationResponse & {
  __typename: 'FileNodeAddMemberMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  member?: Maybe<FileNodeMembership>;
  fileNode?: Maybe<ISharableFileNode>;
};

/** Add a member to a FileNode with the block role. */
export type FileNodeBlockMemberInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  /** The FileNode to modify access to. */
  fileNodeId: Scalars['ID'];
  /** The actor to grant access to. */
  targetActorId: Scalars['ID'];
};

export type FileNodeBlockMemberMutation = MutationResponse & {
  __typename: 'FileNodeBlockMemberMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  member?: Maybe<FileNodeMembership>;
  fileNode?: Maybe<ISharableFileNode>;
};

/** Change the role of an existing member to a FileNode. */
export type FileNodeUpdateMemberInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  /** The member ID to update. */
  id: Scalars['ID'];
  /** The role to grant. */
  fileRoleId: Scalars['ID'];
};

export type FileNodeUpdateMemberMutation = MutationResponse & {
  __typename: 'FileNodeUpdateMemberMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  member?: Maybe<FileNodeMembership>;
  fileNode?: Maybe<ISharableFileNode>;
};

/** Delete a FileNode membership. */
export type FileNodeRemoveMemberInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  /** The member ID to delete. */
  id: Scalars['ID'];
};

export type FileNodeRemoveMemberMutation = MutationResponse & {
  __typename: 'FileNodeRemoveMemberMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileNode?: Maybe<ISharableFileNode>;
};

export type FileNodeAddMemberBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<FileNodeAddMemberBatchItemInput>;
};

export type FileNodeAddMemberBatchItemInput = {
  /** The FileNode to modify access to. */
  fileNodeId: Scalars['ID'];
  /** The actor to grant access to. */
  targetActorId: Scalars['ID'];
  /** The role to grant. */
  fileRoleId: Scalars['ID'];
};

export type FileNodeAddMemberBatchMutation = MutationResponse & {
  __typename: 'FileNodeAddMemberBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<FileNodeAddMemberBatchItemMutation>>>;
  members?: Maybe<Array<Maybe<FileNodeMembership>>>;
  fileNode?: Maybe<ISharableFileNode>;
};

export type FileNodeAddMemberBatchItemMutation = MutationResponse & {
  __typename: 'FileNodeAddMemberBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  actorId?: Maybe<Scalars['ID']>;
  fileNodeId?: Maybe<Scalars['ID']>;
  memberId?: Maybe<Scalars['ID']>;
};

export type FileNodeBlockMemberBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<FileNodeBlockMemberBatchItemInput>;
};

export type FileNodeBlockMemberBatchItemInput = {
  /** The FileNode to modify access to. */
  fileNodeId: Scalars['ID'];
  /** The actor to block access from. */
  targetActorId: Scalars['ID'];
};

export type FileNodeBlockMemberBatchMutation = MutationResponse & {
  __typename: 'FileNodeBlockMemberBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<FileNodeBlockMemberBatchItemMutation>>>;
  members?: Maybe<Array<Maybe<FileNodeMembership>>>;
  fileNode?: Maybe<ISharableFileNode>;
};

export type FileNodeBlockMemberBatchItemMutation = MutationResponse & {
  __typename: 'FileNodeBlockMemberBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  actorId?: Maybe<Scalars['ID']>;
  fileNodeId?: Maybe<Scalars['ID']>;
  memberId?: Maybe<Scalars['ID']>;
};

export type FileNodeUpdateMemberBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<FileNodeBatchIdAndRoleInput>;
};

export type FileNodeBatchIdAndRoleInput = {
  /** The member ID to update. */
  id: Scalars['ID'];
  /** The role to grant. */
  fileRoleId: Scalars['ID'];
};

export type FileNodeUpdateMemberBatchMutation = MutationResponse & {
  __typename: 'FileNodeUpdateMemberBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<FileNodeUpdateMemberBatchItemMutation>>>;
  members?: Maybe<Array<Maybe<FileNodeMembership>>>;
  fileNodes?: Maybe<Array<Maybe<ISharableFileNode>>>;
};

export type FileNodeUpdateMemberBatchItemMutation = MutationResponse & {
  __typename: 'FileNodeUpdateMemberBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  actorId?: Maybe<Scalars['ID']>;
  fileNodeId?: Maybe<Scalars['ID']>;
  memberId?: Maybe<Scalars['ID']>;
};

export type FileNodeRemoveMemberBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  /** The array of member IDs to remove. */
  targets: Array<Scalars['ID']>;
};

export type FileNodeRemoveMemberBatchMutation = MutationResponse & {
  __typename: 'FileNodeRemoveMemberBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<FileNodeRemoveMemberBatchItemMutation>>>;
  fileNodes?: Maybe<Array<Maybe<ISharableFileNode>>>;
};

export type FileNodeRemoveMemberBatchItemMutation = MutationResponse & {
  __typename: 'FileNodeRemoveMemberBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  actorId?: Maybe<Scalars['ID']>;
  fileNodeId?: Maybe<Scalars['ID']>;
  memberId?: Maybe<Scalars['ID']>;
};

/**
 * Limit support for updating recycled file content.
 * Primary use case is resolving file name conflicts before recovering a file.
 */
export type UpdateRecycledFileNodeInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type UpdateRecycledFileNodeMutation = MutationResponse & {
  __typename: 'UpdateRecycledFileNodeMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileNode?: Maybe<IRecycledFileNode>;
};

export type RecycleFileNodeInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
};

export type RecycleFileNodeMutation = MutationResponse & {
  __typename: 'RecycleFileNodeMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileNode?: Maybe<IRecycledFileNode>;
};

export type RecycleFileNodeBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<Scalars['ID']>;
};

export type RecycleFileNodeBatchMutation = MutationResponse & {
  __typename: 'RecycleFileNodeBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<RecycleFileNodeBatchItemMutation>>>;
  fileNodes?: Maybe<Array<Maybe<IRecycledFileNode>>>;
};

export type RecycleFileNodeBatchItemMutation = MutationResponse & {
  __typename: 'RecycleFileNodeBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileNodeId?: Maybe<Scalars['ID']>;
};

export type RecoverFileNodeInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
};

export type RecoverFileNodeMutation = MutationResponse & {
  __typename: 'RecoverFileNodeMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileNode?: Maybe<IFileNode>;
};

export type RecoverFileNodeBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<Scalars['ID']>;
};

export type RecoverFileNodeBatchMutation = MutationResponse & {
  __typename: 'RecoverFileNodeBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<RecoverFileNodeBatchItemMutation>>>;
  fileNodes?: Maybe<Array<Maybe<IFileNode>>>;
};

export type RecoverFileNodeBatchItemMutation = MutationResponse & {
  __typename: 'RecoverFileNodeBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileNodeId?: Maybe<Scalars['ID']>;
};

export type DeleteFileNodeInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
};

export type DeleteFileNodeMutation = MutationResponse & {
  __typename: 'DeleteFileNodeMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type DeleteFileNodeBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<Scalars['ID']>;
};

export type DeleteFileNodeBatchMutation = MutationResponse & {
  __typename: 'DeleteFileNodeBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<DeleteFileNodeBatchItemMutation>>>;
};

export type DeleteFileNodeBatchItemMutation = MutationResponse & {
  __typename: 'DeleteFileNodeBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileNodeId?: Maybe<Scalars['ID']>;
};

export type ResetFileContainerInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /** Skip the change if the FileContainer is already approved. */
  skipApproved?: Maybe<Scalars['Boolean']>;
};

export type ResetFileContainerMutation = MutationResponse & {
  __typename: 'ResetFileContainerMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileNode?: Maybe<FileContainer>;
};

export type CancelFileContainerInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /** Skip the change if the FileContainer is already approved. */
  skipApproved?: Maybe<Scalars['Boolean']>;
};

export type CancelFileContainerMutation = MutationResponse & {
  __typename: 'CancelFileContainerMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileNode?: Maybe<FileContainer>;
};

export type SubmitFileContainerInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /** Skip the change if the FileContainer is already approved. */
  skipApproved?: Maybe<Scalars['Boolean']>;
};

export type SubmitFileContainerMutation = MutationResponse & {
  __typename: 'SubmitFileContainerMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileNode?: Maybe<FileContainer>;
};

export type RejectFileContainerInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /** Skip the change if the FileContainer is already approved. */
  skipApproved?: Maybe<Scalars['Boolean']>;
};

export type RejectFileContainerMutation = MutationResponse & {
  __typename: 'RejectFileContainerMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileNode?: Maybe<FileContainer>;
};

export type ApproveFileContainerInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /** Skip the change if the FileContainer is already approved. */
  skipApproved?: Maybe<Scalars['Boolean']>;
};

export type ApproveFileContainerMutation = MutationResponse & {
  __typename: 'ApproveFileContainerMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileNode?: Maybe<FileContainer>;
};

export type UpdateFileContainerStatusBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<UpdateFileContainerStatusBatchItemInput>;
};

export type UpdateFileContainerStatusBatchItemInput = {
  id: Scalars['ID'];
  status: FileNodeStatus;
  /** Skip the change if the FileContainer is already approved. */
  skipApproved?: Maybe<Scalars['Boolean']>;
};

export type UpdateFileContainerStatusBatchMutation = MutationResponse & {
  __typename: 'UpdateFileContainerStatusBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<UpdateFileContainerStatusBatchItemMutation>>>;
  fileNodes?: Maybe<Array<Maybe<FileContainer>>>;
};

export type UpdateFileContainerStatusBatchItemMutation = MutationResponse & {
  __typename: 'UpdateFileContainerStatusBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  fileNodeId?: Maybe<Scalars['ID']>;
};

export type ArchiveJobInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
};

export type ArchiveJobMutation = MutationResponse & {
  __typename: 'ArchiveJobMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  job?: Maybe<Job>;
};

export type UnarchiveJobInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
};

export type UnarchiveJobMutation = MutationResponse & {
  __typename: 'UnarchiveJobMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  job?: Maybe<Job>;
};

export type ArchiveJobBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<Scalars['ID']>;
};

export type ArchiveJobBatchMutation = MutationResponse & {
  __typename: 'ArchiveJobBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<ArchiveJobBatchItemMutation>>>;
  jobs?: Maybe<Array<Maybe<Job>>>;
};

export type ArchiveJobBatchItemMutation = MutationResponse & {
  __typename: 'ArchiveJobBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  jobId?: Maybe<Scalars['ID']>;
};

export type UnarchiveJobBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<Scalars['ID']>;
};

export type UnarchiveJobBatchMutation = MutationResponse & {
  __typename: 'UnarchiveJobBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<UnarchiveJobBatchItemMutation>>>;
  jobs?: Maybe<Array<Maybe<Job>>>;
};

export type UnarchiveJobBatchItemMutation = MutationResponse & {
  __typename: 'UnarchiveJobBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  jobId?: Maybe<Scalars['ID']>;
};

export type ImportJobInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  job: CreateJobInput;
  milestones?: Maybe<Array<ImportJobDataMilestoneData>>;
  template?: Maybe<Array<ImportJobDataFolderData>>;
};

export type CreateJobInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  projectId: Scalars['ID'];
  status?: Maybe<ProjectStatus>;
  notes?: Maybe<Scalars['String']>;
  jobNumber?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['ISODate']>;
  endDate?: Maybe<Scalars['ISODate']>;
  address?: Maybe<Scalars['ISODate']>;
  location?: Maybe<Scalars['Geography']>;
  /**
   * ProjectTemplate IDs to add to the newly created job.
   * 
   * If omitted or null, defaults to the suggestion=false project templates.
   * Pass an array to override the default list.
   * Pass an empty array to not include any templates.
   */
  templates?: Maybe<Array<Scalars['ID']>>;
};


/** Needs to partially match CreateMilestoneInput */
export type ImportJobDataMilestoneData = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type ImportJobDataFolderData = {
  path: Scalars['String'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  allowFiles?: Maybe<Scalars['Boolean']>;
  /**
   * The maximum number of file that may be uploaded to the folder.
   * If allowFiles is false, this value is ignored.
   */
  maxAllowed?: Maybe<Scalars['Int']>;
  /**
   * Specify a maximum allowed file size for any file uploaded directly to the folder.
   * Does not affect child folders or file containers.
   * null selects the default of the maximum allowed by the Service.
   */
  maxFileSize?: Maybe<Scalars['BigInt']>;
  fileContainers?: Maybe<Array<ImportJobDataFileContainerData>>;
  /**
   * The string value(s) in the array need to be an exact match to a milestone name in
   * ImportJobDataInput.milestones.name
   */
  milestones?: Maybe<Array<Scalars['String']>>;
};

/** Needs to match CreateFileContainerInput */
export type ImportJobDataFileContainerData = {
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  minSuggestion?: Maybe<Scalars['Int']>;
  maxSuggestion?: Maybe<Scalars['Int']>;
  minRequired?: Maybe<Scalars['Int']>;
  maxAllowed?: Maybe<Scalars['Int']>;
  /**
   * Specify a maximum allowed file size for any file uploaded directly to the folder.
   * Does not affect child folders or file containers.
   * null selects the default.
   */
  maxFileSize?: Maybe<Scalars['BigInt']>;
  /** White list of allowed file extensions that may be uploaded. */
  whitelistExtensions?: Maybe<Array<Scalars['String']>>;
  /** Selected standard file type to define file constraints. */
  inheritedFileType?: Maybe<Scalars['ID']>;
  /**
   * The string value(s) in the array need to be an exact match to a milestone name in
   * ImportJobDataInput.milestones.name
   */
  milestones?: Maybe<Array<Scalars['String']>>;
};

export type ImportJobMutation = MutationResponse & {
  __typename: 'ImportJobMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  job?: Maybe<Job>;
};

export type ImportJobDataInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  jobId: Scalars['ID'];
  milestones?: Maybe<Array<ImportJobDataMilestoneData>>;
  template?: Maybe<Array<ImportJobDataFolderData>>;
};

export type ImportJobDataMutation = MutationResponse & {
  __typename: 'ImportJobDataMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  job?: Maybe<Job>;
};

export type CreateJobMutation = MutationResponse & {
  __typename: 'CreateJobMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  job?: Maybe<Job>;
};

export type UpdateJobInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<ProjectStatus>;
  notes?: Maybe<Scalars['String']>;
  jobNumber?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['ISODate']>;
  endDate?: Maybe<Scalars['ISODate']>;
  address?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['Geography']>;
};

export type UpdateJobMutation = MutationResponse & {
  __typename: 'UpdateJobMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  job?: Maybe<Job>;
};

export type DeleteJobInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /**
   * Send the job's folder to the recycle in.
   * 
   * If recycling the folder fails, deleting the job will fail too.
   * This will most commonly happen when the folder is locked or contains something that is locked.
   */
  recycleFolder?: Maybe<Scalars['Boolean']>;
};

export type DeleteJobMutation = MutationResponse & {
  __typename: 'DeleteJobMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type DeleteJobBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<DeleteJobBatchItemInput>;
};

export type DeleteJobBatchItemInput = {
  id: Scalars['ID'];
  /**
   * Send the job's folder to the recycle in.
   * 
   * If recycling the folder fails, deleting the job will fail too.
   * This will most commonly happen when the folder is locked or contains something that is locked.
   */
  recycleFolder?: Maybe<Scalars['Boolean']>;
};

export type DeleteJobBatchMutation = MutationResponse & {
  __typename: 'DeleteJobBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<DeleteJobBatchItemMutation>>>;
};

export type DeleteJobBatchItemMutation = MutationResponse & {
  __typename: 'DeleteJobBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  jobId?: Maybe<Scalars['ID']>;
};

export type ResetJobInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /** Skip the change if the Job is already completed. */
  skipIfDone?: Maybe<Scalars['Boolean']>;
  /** Skip the change if the Job has been cancelled. */
  skipIfCancelled?: Maybe<Scalars['Boolean']>;
};

export type ResetJobMutation = MutationResponse & {
  __typename: 'ResetJobMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  job?: Maybe<Job>;
};

export type StartJobInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /** Skip the change if the Job is already completed. */
  skipIfDone?: Maybe<Scalars['Boolean']>;
  /** Skip the change if the Job has been cancelled. */
  skipIfCancelled?: Maybe<Scalars['Boolean']>;
};

export type StartJobMutation = MutationResponse & {
  __typename: 'StartJobMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  job?: Maybe<Job>;
};

export type CancelJobInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /** Skip the change if the Job is already completed. */
  skipIfDone?: Maybe<Scalars['Boolean']>;
};

export type CancelJobMutation = MutationResponse & {
  __typename: 'CancelJobMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  job?: Maybe<Job>;
};

export type CompleteJobInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /** Skip the change if the Job has been cancelled. */
  skipIfCancelled?: Maybe<Scalars['Boolean']>;
};

export type CompleteJobMutation = MutationResponse & {
  __typename: 'CompleteJobMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  job?: Maybe<Job>;
};

export type UpdateJobStatusBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<UpdateJobStatusBatchItemInput>;
};

export type UpdateJobStatusBatchItemInput = {
  id: Scalars['ID'];
  status: ProjectStatus;
  /** Skip the change if the Job is already completed. */
  skipIfDone?: Maybe<Scalars['Boolean']>;
  /** Skip the change if the Job has been cancelled. */
  skipIfCancelled?: Maybe<Scalars['Boolean']>;
};

export type UpdateJobStatusBatchMutation = MutationResponse & {
  __typename: 'UpdateJobStatusBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<UpdateJobStatusBatchItemMutation>>>;
  jobs?: Maybe<Array<Maybe<Job>>>;
};

export type UpdateJobStatusBatchItemMutation = MutationResponse & {
  __typename: 'UpdateJobStatusBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  jobId?: Maybe<Scalars['ID']>;
};

export type CreateJobTemplateInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  jobId: Scalars['ID'];
  name: Scalars['String'];
  source: Scalars['String'];
  checksum: Scalars['String'];
  version?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fs: Scalars['JSONObject'];
  state: Scalars['JSONObject'];
  variables: Scalars['JSONObject'];
};

export type CreateJobTemplateMutation = MutationResponse & {
  __typename: 'CreateJobTemplateMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  template?: Maybe<JobTemplate>;
};

export type UpdateJobTemplateInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fs?: Maybe<Scalars['JSONObject']>;
  state?: Maybe<Scalars['JSONObject']>;
  variables?: Maybe<Scalars['JSONObject']>;
};

export type UpdateJobTemplateMutation = MutationResponse & {
  __typename: 'UpdateJobTemplateMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  template?: Maybe<JobTemplate>;
};

export type DeleteJobTemplateInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
};

export type DeleteJobTemplateMutation = MutationResponse & {
  __typename: 'DeleteJobTemplateMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type DeleteJobTemplateBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<DeleteJobTemplateBatchItemInput>;
};

export type DeleteJobTemplateBatchItemInput = {
  id: Scalars['ID'];
};

export type DeleteJobTemplateBatchMutation = MutationResponse & {
  __typename: 'DeleteJobTemplateBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<DeleteJobTemplateBatchItemMutation>>>;
};

export type DeleteJobTemplateBatchItemMutation = MutationResponse & {
  __typename: 'DeleteJobTemplateBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  templateId?: Maybe<Scalars['ID']>;
};

export type AcquireLockForTemplateInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  checksum: Scalars['String'];
};

export type AcquireLockForTemplateMutation = {
  __typename: 'AcquireLockForTemplateMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  template?: Maybe<JobTemplate>;
};

export type ReleaseLockForTemplateInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  applied: Scalars['Boolean'];
  source?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  version?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['JSONObject']>;
  variables?: Maybe<Scalars['JSONObject']>;
};

export type ReleaseLockForTemplateMutation = {
  __typename: 'ReleaseLockForTemplateMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  template?: Maybe<JobTemplate>;
};

export type ForceReleaseLockForTemplateInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
};

export type ForceReleaseLockForTemplateMutation = {
  __typename: 'ForceReleaseLockForTemplateMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  template?: Maybe<JobTemplate>;
};

export type CreateMilestoneInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  jobId: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  trackedFiles?: Maybe<Array<Scalars['ID']>>;
};

export type CreateMilestoneMutation = MutationResponse & {
  __typename: 'CreateMilestoneMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  milestone?: Maybe<Milestone>;
};

export type UpdateMilestoneInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  filesToAddTracking?: Maybe<Array<Scalars['ID']>>;
  filesToRemoveTracking?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateMilestoneMutation = MutationResponse & {
  __typename: 'UpdateMilestoneMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  milestone?: Maybe<Milestone>;
};

export type DeleteMilestoneInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
};

export type DeleteMilestoneMutation = MutationResponse & {
  __typename: 'DeleteMilestoneMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type DeleteMilestoneBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<DeleteMilestoneBatchItemInput>;
};

export type DeleteMilestoneBatchItemInput = {
  id: Scalars['ID'];
};

export type DeleteMilestoneBatchMutation = MutationResponse & {
  __typename: 'DeleteMilestoneBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<DeleteMilestoneBatchItemMutation>>>;
};

export type DeleteMilestoneBatchItemMutation = MutationResponse & {
  __typename: 'DeleteMilestoneBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  milestoneId?: Maybe<Scalars['ID']>;
};

export type ResetMilestoneInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /** Skip the change if the Milestone is already approved. */
  skipApproved?: Maybe<Scalars['Boolean']>;
  /** Unlock tracked file containers if currently under review or approved. */
  updateFileLocks?: Maybe<Scalars['Boolean']>;
};

export type ResetMilestoneMutation = MutationResponse & {
  __typename: 'ResetMilestoneMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  milestone?: Maybe<Milestone>;
};

export type CancelMilestoneInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /** Skip the change if the Milestone is already approved. */
  skipApproved?: Maybe<Scalars['Boolean']>;
  /** Unlock tracked file containers if currently under review or approved. */
  updateFileLocks?: Maybe<Scalars['Boolean']>;
};

export type CancelMilestoneMutation = MutationResponse & {
  __typename: 'CancelMilestoneMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  milestone?: Maybe<Milestone>;
};

export type SubmitMilestoneInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /** Skip the change if the Milestone is already approved. */
  skipApproved?: Maybe<Scalars['Boolean']>;
  /** Unlock tracked file containers if currently under review or approved. */
  updateFileLocks?: Maybe<Scalars['Boolean']>;
};

export type SubmitMilestoneMutation = MutationResponse & {
  __typename: 'SubmitMilestoneMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  milestone?: Maybe<Milestone>;
};

export type StartReviewMilestoneInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /** Skip the change if the Milestone is already approved. */
  skipApproved?: Maybe<Scalars['Boolean']>;
  /** Lock tracked file containers by default. */
  updateFileLocks?: Maybe<Scalars['Boolean']>;
};

export type StartReviewMilestoneMutation = MutationResponse & {
  __typename: 'StartReviewMilestoneMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  milestone?: Maybe<Milestone>;
};

export type ApproveMilestoneInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /** Lock tracked file containers by default. */
  updateFileLocks?: Maybe<Scalars['Boolean']>;
};

export type ApproveMilestoneMutation = MutationResponse & {
  __typename: 'ApproveMilestoneMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  milestone?: Maybe<Milestone>;
};

export type UpdateMilestoneStatusBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<UpdateMilestoneStatusBatchItemInput>;
};

export type UpdateMilestoneStatusBatchItemInput = {
  id: Scalars['ID'];
  status: MilestoneStatus;
  /** Skip the change if the Milestone is already approved. */
  skipApproved?: Maybe<Scalars['Boolean']>;
  /**
   * Update file container locks according to the status change.
   * Starting a review or approving with lock tracked file containers by default.
   * Ending a review without approving will unlock tracked file containers by default.
   */
  updateFileLocks?: Maybe<Scalars['Boolean']>;
};

export type UpdateMilestoneStatusBatchMutation = MutationResponse & {
  __typename: 'UpdateMilestoneStatusBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<UpdateMilestoneStatusBatchItemMutation>>>;
  milestones?: Maybe<Array<Maybe<Milestone>>>;
};

export type UpdateMilestoneStatusBatchItemMutation = MutationResponse & {
  __typename: 'UpdateMilestoneStatusBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  milestoneId?: Maybe<Scalars['ID']>;
};

export type SetNotificationsNotifiedInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  notificationIds: Array<Scalars['ID']>;
  toTime?: Maybe<Scalars['String']>;
};

export type SetNotificationsNotifiedMutation = MutationResponse & {
  __typename: 'SetNotificationsNotifiedMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type SetNotificationReadInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  read: Scalars['Boolean'];
};

export type SetNotificationReadMutation = MutationResponse & {
  __typename: 'SetNotificationReadMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  notification?: Maybe<UserNotification>;
};

/**
 * Create a new organization.
 * By default, this mutation will add the requesting user to the new organization with the default administrator role.
 */
export type CreateOrganizationInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  codeName?: Maybe<Scalars['String']>;
  /** Service Admin prop to skip adding the creating user to the orgnization. */
  skipUserAdd?: Maybe<Scalars['Boolean']>;
};

export type CreateOrganizationMutation = MutationResponse & {
  __typename: 'CreateOrganizationMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  organization?: Maybe<Organization>;
};

export type UpdateOrganizationInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  codeName?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationMutation = MutationResponse & {
  __typename: 'UpdateOrganizationMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  organization?: Maybe<Organization>;
};

export type DeleteOrganizationInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['ID']>;
};

export type DeleteOrganizationMutation = MutationResponse & {
  __typename: 'DeleteOrganizationMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

/**
 * Create a new organization.
 * By default, this mutation will add the requesting user to the new organization with the default administrator role.
 */
export type CreateOrganizationUserInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  organizationId?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  autoGenPassword?: Maybe<Scalars['Boolean']>;
  roleId: Scalars['ID'];
  /** May only be set but service admins. */
  verifyEmail?: Maybe<Scalars['Boolean']>;
};

export type CreateOrganizationUserMutation = MutationResponse & {
  __typename: 'CreateOrganizationUserMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  organization?: Maybe<Organization>;
  membership?: Maybe<OrganizationMembership>;
  user?: Maybe<User>;
};

export type AddUserToOrganizationInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  organizationId?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
  roleId: Scalars['ID'];
};

export type AddUserToOrganizationMutation = MutationResponse & {
  __typename: 'AddUserToOrganizationMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  organization?: Maybe<Organization>;
  membership?: Maybe<OrganizationMembership>;
};

export type ChangeUserRoleInOrganizationInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  organizationId?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
  roleId: Scalars['ID'];
};

export type ChangeUserRoleInOrganizationMutation = MutationResponse & {
  __typename: 'ChangeUserRoleInOrganizationMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  organization?: Maybe<Organization>;
  membership?: Maybe<OrganizationMembership>;
};

export type RemoveUserFromOrganizationInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  organizationId?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type RemoveUserFromOrganizationMutation = MutationResponse & {
  __typename: 'RemoveUserFromOrganizationMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  organization?: Maybe<Organization>;
};

export type CreateOrganizationUserBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  organizationId?: Maybe<Scalars['ID']>;
  targets: Array<CreateOrganizationUserBatchItemInput>;
  /** May only be set but service admins. */
  verifyEmail?: Maybe<Scalars['Boolean']>;
};

export type CreateOrganizationUserBatchItemInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  autoGenPassword?: Maybe<Scalars['Boolean']>;
  roleId: Scalars['ID'];
};

export type CreateOrganizationUserBatchMutation = MutationResponse & {
  __typename: 'CreateOrganizationUserBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<CreateOrganizationUserBatchItemMutation>>;
  organization?: Maybe<Organization>;
  memberships?: Maybe<Array<OrganizationMembership>>;
  users?: Maybe<Array<User>>;
};

export type CreateOrganizationUserBatchItemMutation = MutationResponse & {
  __typename: 'CreateOrganizationUserBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  organizationId?: Maybe<Scalars['ID']>;
  membershipId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
};

export type ChangeUserRoleInOrganizationBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  organizationId?: Maybe<Scalars['ID']>;
  targets: Array<ChangeUserRoleInOrganizationBatchItemInput>;
};

export type ChangeUserRoleInOrganizationBatchItemInput = {
  userId: Scalars['ID'];
  roleId: Scalars['ID'];
};

export type ChangeUserRoleInOrganizationBatchMutation = MutationResponse & {
  __typename: 'ChangeUserRoleInOrganizationBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<ChangeUserRoleInOrganizationBatchItemMutation>>>;
  organization?: Maybe<Organization>;
  memberships?: Maybe<Array<Maybe<OrganizationMembership>>>;
};

export type ChangeUserRoleInOrganizationBatchItemMutation = MutationResponse & {
  __typename: 'ChangeUserRoleInOrganizationBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  userId?: Maybe<Scalars['ID']>;
};

export type RemoveUserFromOrganizationBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  organizationId?: Maybe<Scalars['ID']>;
  targets: Array<Scalars['ID']>;
};

export type RemoveUserFromOrganizationBatchMutation = MutationResponse & {
  __typename: 'RemoveUserFromOrganizationBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<RemoveUserFromOrganizationBatchItemMutation>>>;
  organization?: Maybe<Organization>;
};

export type RemoveUserFromOrganizationBatchItemMutation = MutationResponse & {
  __typename: 'RemoveUserFromOrganizationBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  userId?: Maybe<Scalars['ID']>;
};

export type ArchiveProjectInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
};

export type ArchiveProjectMutation = MutationResponse & {
  __typename: 'ArchiveProjectMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  project?: Maybe<Project>;
};

export type UnarchiveProjectInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
};

export type UnarchiveProjectMutation = MutationResponse & {
  __typename: 'UnarchiveProjectMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  project?: Maybe<Project>;
};

export type ArchiveProjectBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<Scalars['ID']>;
};

export type ArchiveProjectBatchMutation = MutationResponse & {
  __typename: 'ArchiveProjectBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<ArchiveProjectBatchItemMutation>>>;
  projects?: Maybe<Array<Maybe<Project>>>;
};

export type ArchiveProjectBatchItemMutation = MutationResponse & {
  __typename: 'ArchiveProjectBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  projectId?: Maybe<Scalars['ID']>;
};

export type UnarchiveProjectBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<Scalars['ID']>;
};

export type UnarchiveProjectBatchMutation = MutationResponse & {
  __typename: 'UnarchiveProjectBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<UnarchiveProjectBatchItemMutation>>>;
  projects?: Maybe<Array<Maybe<Project>>>;
};

export type UnarchiveProjectBatchItemMutation = MutationResponse & {
  __typename: 'UnarchiveProjectBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  projectId?: Maybe<Scalars['ID']>;
};

export type CreateProjectInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  status?: Maybe<ProjectStatus>;
  /** Defaults to current Tenant ID. */
  organizationId?: Maybe<Scalars['ID']>;
};

export type CreateProjectMutation = MutationResponse & {
  __typename: 'CreateProjectMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  project?: Maybe<Project>;
};

export type UpdateProjectInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  status?: Maybe<ProjectStatus>;
};

export type UpdateProjectMutation = MutationResponse & {
  __typename: 'UpdateProjectMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  project?: Maybe<Project>;
};

export type DeleteProjectInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /**
   * Send the project's folder to the recycle in.
   * 
   * If recycling the folder fails, deleting the project will fail too.
   * This will most commonly happen when the folder is locked or contains something that is locked.
   */
  recycleFolder?: Maybe<Scalars['Boolean']>;
};

export type DeleteProjectMutation = MutationResponse & {
  __typename: 'DeleteProjectMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type DeleteProjectBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<DeleteProjectBatchItemInput>;
};

export type DeleteProjectBatchItemInput = {
  id: Scalars['ID'];
  /**
   * Send the project's folder to the recycle in.
   * 
   * If recycling the folder fails, deleting the project will fail too.
   * This will most commonly happen when the folder is locked or contains something that is locked.
   */
  recycleFolder?: Maybe<Scalars['Boolean']>;
};

export type DeleteProjectBatchMutation = MutationResponse & {
  __typename: 'DeleteProjectBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<DeleteProjectBatchItemMutation>>>;
};

export type DeleteProjectBatchItemMutation = MutationResponse & {
  __typename: 'DeleteProjectBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  projectId?: Maybe<Scalars['ID']>;
};

export type ResetProjectInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /** Skip the change if the Project is already completed. */
  skipIfDone?: Maybe<Scalars['Boolean']>;
  /** Skip the change if the Project has been cancelled. */
  skipIfCancelled?: Maybe<Scalars['Boolean']>;
};

export type ResetProjectMutation = MutationResponse & {
  __typename: 'ResetProjectMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  project?: Maybe<Project>;
};

export type StartProjectInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /** Skip the change if the Project is already completed. */
  skipIfDone?: Maybe<Scalars['Boolean']>;
  /** Skip the change if the Project has been cancelled. */
  skipIfCancelled?: Maybe<Scalars['Boolean']>;
};

export type StartProjectMutation = MutationResponse & {
  __typename: 'StartProjectMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  project?: Maybe<Project>;
};

export type CancelProjectInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /** Skip the change if the Project is already completed. */
  skipIfDone?: Maybe<Scalars['Boolean']>;
};

export type CancelProjectMutation = MutationResponse & {
  __typename: 'CancelProjectMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  project?: Maybe<Project>;
};

export type CompleteProjectInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /** Skip the change if the Project has been cancelled. */
  skipIfCancelled?: Maybe<Scalars['Boolean']>;
};

export type CompleteProjectMutation = MutationResponse & {
  __typename: 'CompleteProjectMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  project?: Maybe<Project>;
};

export type UpdateProjectStatusBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<UpdateProjectStatusBatchItemInput>;
};

export type UpdateProjectStatusBatchItemInput = {
  id: Scalars['ID'];
  status: ProjectStatus;
  /** Skip the change if the Project is already completed. */
  skipIfDone?: Maybe<Scalars['Boolean']>;
  /** Skip the change if the Project has been cancelled. */
  skipIfCancelled?: Maybe<Scalars['Boolean']>;
};

export type UpdateProjectStatusBatchMutation = MutationResponse & {
  __typename: 'UpdateProjectStatusBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<UpdateProjectStatusBatchItemMutation>>>;
  projects?: Maybe<Array<Maybe<Project>>>;
};

export type UpdateProjectStatusBatchItemMutation = MutationResponse & {
  __typename: 'UpdateProjectStatusBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  projectId?: Maybe<Scalars['ID']>;
};

export type CreateProjectTemplateInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  projectId: Scalars['ID'];
  name: Scalars['String'];
  source: Scalars['String'];
  checksum: Scalars['String'];
  version?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fs: Scalars['JSONObject'];
  suggestion: Scalars['Boolean'];
  includeFilter?: Maybe<ProjectTemplateIncludeFilter>;
};

export type ProjectTemplateIncludeFilter = {
  status?: Maybe<Array<ProjectStatus>>;
};

export type CreateProjectTemplateMutation = MutationResponse & {
  __typename: 'CreateProjectTemplateMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  template?: Maybe<ProjectTemplate>;
};

export type UpdateProjectTemplateInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fs?: Maybe<Scalars['JSONObject']>;
};

export type UpdateProjectTemplateMutation = MutationResponse & {
  __typename: 'UpdateProjectTemplateMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  template?: Maybe<ProjectTemplate>;
};

export type DeleteProjectTemplateInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
};

export type DeleteProjectTemplateMutation = MutationResponse & {
  __typename: 'DeleteProjectTemplateMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type DeleteProjectTemplateBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<DeleteProjectTemplateBatchItemInput>;
};

export type DeleteProjectTemplateBatchItemInput = {
  id: Scalars['ID'];
};

export type DeleteProjectTemplateBatchMutation = MutationResponse & {
  __typename: 'DeleteProjectTemplateBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<DeleteProjectTemplateBatchItemMutation>>>;
};

export type DeleteProjectTemplateBatchItemMutation = MutationResponse & {
  __typename: 'DeleteProjectTemplateBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  templateId?: Maybe<Scalars['ID']>;
};

export type AccessedTenantMutation = MutationResponse & {
  __typename: 'AccessedTenantMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  recentTenants?: Maybe<Array<Organization>>;
};

export type AccessedProjectMutation = MutationResponse & {
  __typename: 'AccessedProjectMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  recentProjects?: Maybe<Array<Project>>;
};

export type AccessedJobMutation = MutationResponse & {
  __typename: 'AccessedJobMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  recentJobs?: Maybe<Array<Job>>;
};

export type AccessedFolderMutation = MutationResponse & {
  __typename: 'AccessedFolderMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  recentFolders?: Maybe<Array<Folder>>;
};

export type AccessedFileMutation = MutationResponse & {
  __typename: 'AccessedFileMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  recentFiles?: Maybe<Array<IFile>>;
};

export type CreateTemplateRepositoryInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  displayName: Scalars['String'];
  moduleName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  public: Scalars['Boolean'];
  draft?: Maybe<Scalars['JSONObject']>;
  /** Defaults to current Tenant ID. */
  organizationId?: Maybe<Scalars['ID']>;
};

export type CreateTemplateRepositoryMutation = MutationResponse & {
  __typename: 'CreateTemplateRepositoryMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  repository?: Maybe<TemplateRepository>;
};

export type UpdateTemplateRepositoryInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
  moduleName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  draft?: Maybe<Scalars['JSONObject']>;
};

export type UpdateTemplateRepositoryMutation = MutationResponse & {
  __typename: 'UpdateTemplateRepositoryMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  repository?: Maybe<TemplateRepository>;
};

export type DeleteTemplateRepositoryInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
};

export type DeleteTemplateRepositoryMutation = MutationResponse & {
  __typename: 'DeleteTemplateRepositoryMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type DeleteTemplateRepositoryBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<DeleteTemplateRepositoryBatchItemInput>;
};

export type DeleteTemplateRepositoryBatchItemInput = {
  id: Scalars['ID'];
};

export type DeleteTemplateRepositoryBatchMutation = MutationResponse & {
  __typename: 'DeleteTemplateRepositoryBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<DeleteTemplateRepositoryBatchItemMutation>>>;
};

export type DeleteTemplateRepositoryBatchItemMutation = MutationResponse & {
  __typename: 'DeleteTemplateRepositoryBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  repositoryId?: Maybe<Scalars['ID']>;
};

export type CreateTemplateCommitInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  repositoryId: Scalars['ID'];
  version?: Maybe<Scalars['String']>;
  checksum: Scalars['String'];
  message: Scalars['String'];
  /** Only UTF-8 text files and directories are supported. */
  fs: Scalars['JSONObject'];
};

export type CreateTemplateCommitMutation = MutationResponse & {
  __typename: 'CreateTemplateCommitMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  commit?: Maybe<TemplateCommit>;
};

export type UpdateTemplateCommitInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  version?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type UpdateTemplateCommitMutation = MutationResponse & {
  __typename: 'UpdateTemplateCommitMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  commit?: Maybe<TemplateCommit>;
};

export type ResetToTemplateCommitInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
};

export type ResetToTemplateCommitMutation = MutationResponse & {
  __typename: 'ResetToTemplateCommitMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  commit?: Maybe<TemplateCommit>;
  repository?: Maybe<TemplateRepository>;
};

export type DeleteTemplateCommitInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
};

export type DeleteTemplateCommitMutation = MutationResponse & {
  __typename: 'DeleteTemplateCommitMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type DeleteTemplateCommitBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<DeleteTemplateCommitBatchItemInput>;
};

export type DeleteTemplateCommitBatchItemInput = {
  id: Scalars['ID'];
};

export type DeleteTemplateCommitBatchMutation = MutationResponse & {
  __typename: 'DeleteTemplateCommitBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<DeleteTemplateCommitBatchItemMutation>>>;
};

export type DeleteTemplateCommitBatchItemMutation = MutationResponse & {
  __typename: 'DeleteTemplateCommitBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  commitId?: Maybe<Scalars['ID']>;
};

export type CreateTeamInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  /**
   * Add users to the team.
   * Array values must be valid User IDs.
   * 
   * Permission: team_members_manage
   */
  members?: Maybe<Array<Scalars['ID']>>;
  /** Defaults to current Tenant ID. */
  organizationId?: Maybe<Scalars['ID']>;
};

export type CreateTeamMutation = MutationResponse & {
  __typename: 'CreateTeamMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  team?: Maybe<Team>;
};

/** Note that including the same user in both usersToAdd and usersToRemove will result in a validation error. */
export type UpdateTeamInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  /**
   * Update the name of the team.
   * 
   * Permission: team_data_update
   */
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  /**
   * Add additional users to the team.
   * Array values must be valid User IDs.
   * 
   * Permission: team_members_manage
   */
  usersToAdd?: Maybe<Array<Scalars['ID']>>;
  /**
   * Remove users from the team.
   * Array values must be valid User IDs.
   * 
   * Permission: team_members_manage
   */
  usersToRemove?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateTeamMutation = MutationResponse & {
  __typename: 'UpdateTeamMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  team?: Maybe<Team>;
};

export type DeleteTeamInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
};

export type DeleteTeamMutation = MutationResponse & {
  __typename: 'DeleteTeamMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type AddMemberToTeamInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

export type AddMemberToTeamMutation = MutationResponse & {
  __typename: 'AddMemberToTeamMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  team?: Maybe<Team>;
};

export type RemoveMemberFromTeamInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

export type RemoveMemberFromTeamMutation = MutationResponse & {
  __typename: 'RemoveMemberFromTeamMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  team?: Maybe<Team>;
};

export type DeleteTeamBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<DeleteTeamBatchItemInput>;
};

export type DeleteTeamBatchItemInput = {
  id: Scalars['ID'];
};

export type DeleteTeamBatchMutation = MutationResponse & {
  __typename: 'DeleteTeamBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<DeleteTeamBatchItemMutation>>>;
};

export type DeleteTeamBatchItemMutation = MutationResponse & {
  __typename: 'DeleteTeamBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  teamId?: Maybe<Scalars['ID']>;
};

export type AddMemberToTeamBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<TeamMemberBatchInput>;
};

export type TeamMemberBatchInput = {
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type AddMemberToTeamBatchMutation = MutationResponse & {
  __typename: 'AddMemberToTeamBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  teams?: Maybe<Array<Maybe<Team>>>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type RemoveMemberFromTeamBatchInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  targets: Array<TeamMemberBatchInput>;
};

export type RemoveMemberFromTeamBatchMutation = MutationResponse & {
  __typename: 'RemoveMemberFromTeamBatchMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  responses?: Maybe<Array<Maybe<RemoveMemberFromTeamBatchItemMutation>>>;
  teams?: Maybe<Array<Maybe<Team>>>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type RemoveMemberFromTeamBatchItemMutation = MutationResponse & {
  __typename: 'RemoveMemberFromTeamBatchItemMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  teamId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
};

export type CreateApplicationInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type CreateApplicationMutation = MutationResponse & {
  __typename: 'CreateApplicationMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  application?: Maybe<Application>;
};

export type UpdateApplicationInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** Create a new clientSecret. */
  rotateClientSecret?: Maybe<Scalars['Boolean']>;
};

export type UpdateApplicationMutation = MutationResponse & {
  __typename: 'UpdateApplicationMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  application?: Maybe<Application>;
};

export type DeleteApplicationInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
};

export type DeleteApplicationMutation = MutationResponse & {
  __typename: 'DeleteApplicationMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type CreateUserInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  /** May only be set but service admins. */
  verifyEmail?: Maybe<Scalars['Boolean']>;
};

export type CreateUserMutation = MutationResponse & {
  __typename: 'CreateUserMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  user?: Maybe<User>;
};

export type UpdateUserInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** TODO: document */
  currentPassword?: Maybe<Scalars['String']>;
  /** TODO: document */
  newPassword?: Maybe<Scalars['String']>;
};

export type UpdateUserMutation = MutationResponse & {
  __typename: 'UpdateUserMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  user?: Maybe<User>;
};

export type DeleteUserInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  id: Scalars['ID'];
};

export type DeleteUserMutation = MutationResponse & {
  __typename: 'DeleteUserMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type SetUsersLastEventualNotificationInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  userIds: Array<Scalars['ID']>;
  toTime?: Maybe<Scalars['String']>;
};

export type SetUsersLastEventualNotificationMutation = MutationResponse & {
  __typename: 'SetUsersLastEventualNotificationMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

export enum ActorSortField {
  Id = 'ID',
  Name = 'NAME'
}

export type RetryUploadInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  /** The ID returned by the original file upload request. */
  id: Scalars['ID'];
};

export type RetryUploadMutation = MutationResponse & IFileUploadRequest & {
  __typename: 'RetryUploadMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  signedRequestUrl?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<FileUploadRequestField>>;
  fileId?: Maybe<Scalars['ID']>;
  file?: Maybe<FileSubmission>;
};

export type RetryBatchUploadInput = {
  clientMutationId?: Maybe<Scalars['UUID']>;
  requests: Array<RetryUploadInput>;
};

export type RetryBatchUploadMutation = MutationResponse & {
  __typename: 'RetryBatchUploadMutation';
  clientMutationId?: Maybe<Scalars['UUID']>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
  requests: Array<RetryUploadMutation>;
};

/** Look up FileContainer Listings */
export type FileContainerConnection = {
  __typename: 'FileContainerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<FileContainerEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  total?: Maybe<Scalars['Int']>;
};

/** An edge in a FileContainerConnection. */
export type FileContainerEdge = {
  __typename: 'FileContainerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<FileContainer>;
};

export enum FileRoleSortField {
  Id = 'ID',
  Name = 'NAME'
}

export enum FileNodeMembershipSortField {
  Id = 'ID',
  ActorType = 'ACTOR_TYPE',
  ActorName = 'ACTOR_NAME'
}

export enum FileNodeCollaboratorSortField {
  Id = 'ID',
  ActorName = 'ACTOR_NAME'
}

export type RecycledFile = Node & IRecycledFileNode & IRecycledFile & {
  __typename: 'RecycledFile';
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  parent?: Maybe<UFolder>;
  originalPath: Scalars['String'];
  originalPathIds: Array<Scalars['ID']>;
  currentPath: Scalars['String'];
  currentPathIds: Array<Scalars['ID']>;
  fullPath: Scalars['String'];
  depth: Scalars['Int'];
  fileNodeType: FileNodeType;
  url: Scalars['String'];
  preview?: Maybe<FilePreview>;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  deletedById: Scalars['ID'];
  deletedBy: User;
  deletedAt: Scalars['String'];
  hasPermissions?: Maybe<PermissionMap>;
};


export type RecycledFilePreviewArgs = {
  options?: Maybe<FilePreviewOptions>;
};


export type RecycledFileCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type RecycledFileDeletedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};

export type UFileContainer = FileContainer | RecycledFileContainer;

export type RecycledFileSubmission = Node & IRecycledFileNode & IRecycledFile & {
  __typename: 'RecycledFileSubmission';
  id: Scalars['ID'];
  mutationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  originalName: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
  parent?: Maybe<UFolder>;
  fileContainerId: Scalars['ID'];
  fileContainer: UFileContainer;
  originalPath: Scalars['String'];
  originalPathIds: Array<Scalars['ID']>;
  currentPath: Scalars['String'];
  currentPathIds: Array<Scalars['ID']>;
  fullPath: Scalars['String'];
  depth: Scalars['Int'];
  fileNodeType: FileNodeType;
  status: FileNodeStatus;
  url: Scalars['String'];
  preview?: Maybe<FilePreview>;
  allowCustomName?: Maybe<Scalars['Boolean']>;
  createdById: Scalars['ID'];
  createdBy: IUser;
  createdAt: Scalars['String'];
  deletedById: Scalars['ID'];
  deletedBy: User;
  deletedAt: Scalars['String'];
  hasPermissions?: Maybe<PermissionMap>;
};


export type RecycledFileSubmissionPreviewArgs = {
  options?: Maybe<FilePreviewOptions>;
};


export type RecycledFileSubmissionCreatedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};


export type RecycledFileSubmissionDeletedAtArgs = {
  dateFormat?: Maybe<DateFormatInput>;
};

export type NotificationsWithUsers = {
  __typename: 'NotificationsWithUsers';
  users: Array<User>;
  notifications: Array<UserNotification>;
};

export enum TemplateInclusion {
  All = 'ALL',
  AutomaticOnly = 'AUTOMATIC_ONLY',
  SuggestedOnly = 'SUGGESTED_ONLY'
}

type MilestoneTrackedFile_Folder_Fragment = (
  { __typename: 'Folder' }
  & Pick<Folder, 'id' | 'name'>
);

type MilestoneTrackedFile_FileContainer_Fragment = (
  { __typename: 'FileContainer' }
  & Pick<FileContainer, 'id' | 'name'>
);

type MilestoneTrackedFile_File_Fragment = (
  { __typename: 'File' }
  & Pick<File, 'id' | 'name'>
);

type MilestoneTrackedFile_FileSubmission_Fragment = (
  { __typename: 'FileSubmission' }
  & Pick<FileSubmission, 'id' | 'name'>
);

export type MilestoneTrackedFileFragment = MilestoneTrackedFile_Folder_Fragment | MilestoneTrackedFile_FileContainer_Fragment | MilestoneTrackedFile_File_Fragment | MilestoneTrackedFile_FileSubmission_Fragment;

export type MilestoneTrackedFileConnectionFragment = (
  { __typename: 'IFileNodeConnection' }
  & { pageInfo: (
    { __typename: 'PageInfo' }
    & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  ), edges?: Maybe<Array<Maybe<(
    { __typename: 'IFileNodeEdge' }
    & { node?: Maybe<(
      { __typename: 'Folder' }
      & MilestoneTrackedFile_Folder_Fragment
    ) | (
      { __typename: 'FileContainer' }
      & MilestoneTrackedFile_FileContainer_Fragment
    ) | (
      { __typename: 'File' }
      & MilestoneTrackedFile_File_Fragment
    ) | (
      { __typename: 'FileSubmission' }
      & MilestoneTrackedFile_FileSubmission_Fragment
    )> }
  )>>> }
);

export type MilestoneFileConnectionFragment = (
  { __typename: 'IFileNodeConnection' }
  & { pageInfo: (
    { __typename: 'PageInfo' }
    & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  ), edges?: Maybe<Array<Maybe<(
    { __typename: 'IFileNodeEdge' }
    & { node?: Maybe<(
      { __typename: 'Folder' }
      & MilestoneFile_Folder_Fragment
    ) | (
      { __typename: 'FileContainer' }
      & MilestoneFile_FileContainer_Fragment
    ) | (
      { __typename: 'File' }
      & MilestoneFile_File_Fragment
    ) | (
      { __typename: 'FileSubmission' }
      & MilestoneFile_FileSubmission_Fragment
    )> }
  )>>> }
);

type MilestoneFile_Folder_Fragment = (
  { __typename: 'Folder' }
  & Pick<Folder, 'id' | 'name'>
);

type MilestoneFile_FileContainer_Fragment = (
  { __typename: 'FileContainer' }
  & Pick<FileContainer, 'id' | 'name'>
);

type MilestoneFile_File_Fragment = (
  { __typename: 'File' }
  & Pick<File, 'id' | 'name'>
);

type MilestoneFile_FileSubmission_Fragment = (
  { __typename: 'FileSubmission' }
  & Pick<FileSubmission, 'fileContainerId' | 'id' | 'name'>
);

export type MilestoneFileFragment = MilestoneFile_Folder_Fragment | MilestoneFile_FileContainer_Fragment | MilestoneFile_File_Fragment | MilestoneFile_FileSubmission_Fragment;

export type MilestoneDisplayInfoQueryVariables = Exact<{
  id: Scalars['ID'];
  firstTrckedFiles: Scalars['Int'];
  firstFiles: Scalars['Int'];
}>;


export type MilestoneDisplayInfoQueryResult = (
  { __typename: 'Query' }
  & { milestoneById?: Maybe<(
    { __typename: 'Milestone' }
    & Pick<Milestone, 'id' | 'name' | 'description'>
    & { job: (
      { __typename: 'Job' }
      & Pick<Job, 'id' | 'name'>
      & { project: (
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name'>
        & { organization: (
          { __typename: 'Organization' }
          & Pick<Organization, 'id' | 'name'>
        ) }
      ) }
    ), trackedFiles: (
      { __typename: 'IFileNodeConnection' }
      & MilestoneTrackedFileConnectionFragment
    ), files: (
      { __typename: 'IFileNodeConnection' }
      & MilestoneFileConnectionFragment
    ) }
  )> }
);

export type MoreMilestoneTrackedFilesQueryVariables = Exact<{
  id: Scalars['ID'];
  first: Scalars['Int'];
  cursor: Scalars['String'];
}>;


export type MoreMilestoneTrackedFilesQueryResult = (
  { __typename: 'Query' }
  & { milestoneById?: Maybe<(
    { __typename: 'Milestone' }
    & { trackedFiles: (
      { __typename: 'IFileNodeConnection' }
      & MilestoneTrackedFileConnectionFragment
    ) }
  )> }
);

export type MoreMilestoneFilesQueryVariables = Exact<{
  id: Scalars['ID'];
  first: Scalars['Int'];
  cursor: Scalars['String'];
}>;


export type MoreMilestoneFilesQueryResult = (
  { __typename: 'Query' }
  & { milestoneById?: Maybe<(
    { __typename: 'Milestone' }
    & { files: (
      { __typename: 'IFileNodeConnection' }
      & MilestoneFileConnectionFragment
    ) }
  )> }
);

export type UpdateMilestoneMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type UpdateMilestoneMutationResult = (
  { __typename: 'Mutation' }
  & { updateMilestone?: Maybe<(
    { __typename: 'UpdateMilestoneMutation' }
    & Pick<UpdateMilestoneMutation, 'code' | 'success' | 'message'>
    & { validationErrors?: Maybe<Array<Maybe<(
      { __typename: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'message'>
    )>>>, milestone?: Maybe<(
      { __typename: 'Milestone' }
      & Pick<Milestone, 'name' | 'description'>
    )> }
  )> }
);

export type OnboardCreateOrgMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type OnboardCreateOrgMutationResult = (
  { __typename: 'Mutation' }
  & { createOrganization?: Maybe<(
    { __typename: 'CreateOrganizationMutation' }
    & Pick<CreateOrganizationMutation, 'code' | 'success' | 'message'>
    & { organization?: Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id'>
    )> }
  )> }
);
