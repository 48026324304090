import {initAuth0} from '@auth0/nextjs-auth0';
import {config} from './config';


export const auth0 = initAuth0({
  baseURL: config.CLIENT_DOMAIN,
  issuerBaseURL: `https://${config.AUTH0_DOMAIN as string}`,
  clientID: config.AUTH0_CLIENT_ID as string,
  clientSecret: config.AUTH0_CLIENT_SECRET,
  secret: config.AUTH0_COOKIE_SECRET as string,
  clockTolerance: 60, // seconds
  authorizationParams: {
    scope: 'openid profile offline_access',
    audience: config.AUTH0_AUDIENCE,
  },
  routes: {
    callback: '/api/callback',
    postLogoutRedirect: '/goodbye',
  },
  session: {
    rollingDuration: config.AUTH0_COOKIE_LIFETIME || 60 * 60 * 24 * 3,
    absoluteDuration: 60 * 60 * 24 * 30,
    // (Optional) SameSite configuration for the session cookie. Defaults to 'lax', but can be changed to 'strict' or 'none'. Set it to false if you want to disable the SameSite setting.
    cookie: {
      sameSite: 'lax',
    },
  },
});

export async function getCachedToken(req, res) {
  try {
    const {accessToken} = await auth0.getAccessToken(req, res);
    return accessToken;
  } catch (e) {
    console.warn(e.message);
    // May want to log errors here. normally this would be failure to refresh token.
    // Caller expected to handle no token as access failure.
    // return undefined
  }
}
